export const GetUpcomingEvents = "/webapi/getupcomingevents";
export const AddContactInquiry = "/webapi/addcontactinquiry";
export const GetEventDetail = "/webapi/geteventdetail";
export const GenerateOtp = "/webapi/generateotp";
export const ValidateOtp = "/webapi/validateotp";
export const SocialSignup = "/webapi/socialsignup";
export const Signup = "/webapi/signup";
export const validateJwtToken = "/authorizationapi/validatejwttoken";
export const validateInvitedUser = "/authorizationapi/validateInvitedUser";
export const genetareToken = "/authorizationapi/generate-jwt";
export const GetUserProfile = "/webapi/getuser";
export const EditUserProfile = "/webapi/edituser";
export const GetAllTransactions = "/webapi/getalltransactions";
export const Getallparticipatedevents = "/webapi/getallparticipatedevents";
export const GetAllStatesById = "/webapi/getstates";
export const GetParticipatedEventUsers =
  "/webapi/checkanyparticipatedeventbypageurl";
export const GetActivityjournal = "/webapi/journal";
export const GetActivity = "/webapi/getuseractivitybyid";
export const joinFreeEvent = "/webapi/joinfreeevent";
export const joinPaidEvent = "/webapi/createorderid";
export const razorpayPayment = "/webapi/addpaymenthistory";
export const GetPopularEvents = "/webapi/get-popular-events";
export const GetAllEvents = "/webapi/getallevents";
export const USER_DEFAULT_IMAGE_PATH =
  "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp";

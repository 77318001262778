import React, { useState, useRef} from "react";
import { Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { usePostQuery } from "../../../../hooks/reactQueryHelper";
import { Formik } from "formik";
import { Signup } from "../../../../services/Web.type";
import moment from "moment-timezone";
import { SignupStep2FormValues } from "../../core/_models";
import toastify from "../../../../helper/toastify";
import { Spinner } from "react-bootstrap";
interface Props {
  onNextStep: () => void;
  SignUpMobile: string;
  setData: (data: any) => void; 
}

const SignupStep2: React.FC<Props> = ({
  onNextStep,
  SignUpMobile,
  setData,
}) => {
  const SignupQuery = usePostQuery("validateOtpQuery", Signup, {});
  const initialValues = {
    firstname: "",
    surname: "",
    weight: "",
    height: "",
    dob: "",
    email: "",
    mobile: "",
    gender: "",
    termcheckbox: false,
  };
  
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Please enter a firstname!"),
    surname: Yup.string().required("Please enter a surname!"),
    weight: Yup.number().required("Please enter a weight!"),
    height: Yup.number().required("Please enter a height!"),
    dob: Yup.date().required("Please select a date of birth!"),
    email: Yup.string()
      .email("Please enter a valid email address!")
      .required("Please enter an email address!"),
    gender: Yup.string()
      .oneOf(["M", "F"], "Please select a gender!")
      .required("Please select a gender!"),
    termcheckbox: Yup.boolean()
      .oneOf([true], "Please select a the terms of use and privacy policy!")
      .required("Please agree to the terms of use and privacy policy!"),
  });


  const [globalErrors] = useState("");
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (values: SignupStep2FormValues) => {
    setLoading(true);
    const newRecord = {
      firstname: values.firstname,
      surname: values.surname,
      weight: Number(values.weight),
      height: Number(values.height),
      dob: moment(values.dob).tz("Asia/Kolkata").format(),
      email: values.email,
      mobile: SignUpMobile,
      gender: values.gender,
    };

    SignupQuery.mutate(newRecord, {
      onSuccess: (data) => {

        setData(data.result);
    
        onNextStep();
      },
      onError: (error) => {
        const errorMessage =
        error.response?.data?.responseException?.exceptionMessage ||
        "An error occurred while SignUp.";
        toastify("error", errorMessage);
        console.error("Error generating OTP:", error);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };
  const getEightYearsAgo = () => {
    const eightYearsAgo = new Date();
    eightYearsAgo.setFullYear(eightYearsAgo.getFullYear() - 8);
    return eightYearsAgo.toISOString().split('T')[0];
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        errors,
      }) => {
        return(
        <form onSubmit={handleSubmit}>
          <div className="row ">
            <div className="col-12">
            <h3 className="auth-font-main fw-600 font-35 ">Let’s get to know<br/> you better!</h3>
            </div>
            <div className="col-12">
              <p className="auth-font-18 text-secondary fw-400 font-16">
                Provide us with some of your basic information to serve you
                better.
              </p>
            </div>

            <div className={`col-md-6  mb-2`}>
              <input
                type="text"
                className={`form-control rounded  auth-input   ${
                  touched.firstname && errors.firstname ? "border-danger" : ""
                }`}
                placeholder="First name"
                name="firstname"
                id="firstname"
                value={values.firstname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.firstname && errors.firstname && (
                <div className="text-danger">{errors.firstname}</div>
              )}
            </div>
            <div className={`col-md-6  mb-2`}>
              <input
                type="text"
                className={`form-control rounded auth-input   ${
                  touched.surname && errors.surname ? "border-danger" : ""
                }`}
                placeholder="surname"
                name="surname"
                id="surname"
                value={values.surname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.surname && errors.surname && (
                <div className="text-danger ">{errors.surname}</div>
              )}
            </div>
            <div className="col-12 mb-2">
             <input
               type="date"
               id="dob"
               name="dob"
               value={values.dob}
               onChange={handleChange}
               onBlur={handleBlur}
               placeholder="Date of birth"
               className={`form-control me-3 rounded auth-input ${
               touched.dob && errors.dob ? "border-danger" : ""
               }`}
               max={getEightYearsAgo()}
               />
          {touched.dob && errors.dob && (
             <div className="text-danger">{errors.dob}</div>
          )}
       </div>
            <div className="col-12 mb-2">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className={`form-control  me-3 rounded  auth-input  ${
                    touched.weight && errors.weight ? "border-danger" : ""
                  }`}
                  placeholder="Weight"
                  style={{ paddingRight: "100px" }}
                  name="weight"
                  id="weight"
                  value={values.weight}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");

                    handleChange({
                      target: {
                        name: "weight",
                        value: newValue,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                />
                <button
                  type="button"
                  className="btn btn-link input-inner-right-btn"
                >
                  KG
                </button>
              </div>
              {touched.weight && errors.weight && (
                <div className="text-danger">{errors.weight}</div>
              )}
            </div>
            <div className="col-12 mb-2">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  className={`form-control  me-3 rounded  auth-input    ${
                    touched.height && errors.height ? "border-danger" : ""
                  }`}
                  placeholder="Height"
                  style={{ paddingRight: "100px" }}
                  name="height"
                  id="height"
                  value={values.height}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, "");

                    handleChange({
                      target: {
                        name: "height",
                        value: newValue,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                />
                <button
                  type="button"
                  className="btn btn-link input-inner-right-btn"
                >
                  CM
                </button>
              </div>
              {touched.height && errors.height && (
                <div className="text-danger">{errors.height}</div>
              )}
            </div>
            <div className="col-12 mb-2">
              <input
                type="text"
                className={`form-control  me-3 rounded  auth-input   ${
                  touched.email && errors.email && "border-danger"
                }`}
                placeholder="Email"
                style={{ paddingRight: "100px" }}
                name="email"
                id="email"
                value={values.email}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^\w@.-]/g, "");

                  handleChange({
                    target: {
                      name: "email",
                      value: newValue,
                    },
                  });
                }}
                onBlur={handleBlur}
              />

              {touched.email && errors.email && (
                <div className="text-danger">{errors.email}</div>
              )}
            </div>

            <div className="col-auto mb-2">
              <input
                type="radio"
                id="male"
                name="gender"
                value="M"
                onChange={handleChange}
                className={`form-check-input me-2  ${
                  touched.gender && errors.gender && "border-danger"
                }`}
              />
              <label
                className={`form-check-label auth-input  ${
                  touched.gender && errors.gender && "text-danger"
                }`}
              >
                Male
              </label>
            </div>
            <div className="col-auto mb-2">
              <input
                type="radio"
                id="female"
                name="gender"
                value="F"
                onChange={handleChange}
                className={`form-check-input me-2  ${
                  touched.gender && errors.gender && "border-danger"
                }`}
              />
              <label
                className={`form-check-label auth-input  ${
                  touched.gender && errors.gender && "text-danger"
                }`}
              >
                Female
              </label>
            </div>

            <div className="col-12 mb-3">
              <button
                type="submit"
                className="btn  default-background-color  rounded-pill d-flex align-items-center w-100 justify-content-center  p-2 opacity-60 text-center fw-500  "
              >
               {loading ? (
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                   
                  />
                ) :
                  (<span className="indicator-label">Sign Up</span>)}
                 
              </button>
              {globalErrors && (
                <div className="text-danger text-center">{globalErrors}</div>
              )}
          
            </div>

            <div className="d-flex col-12 ">
              <input
                type="checkbox"
                id="termcheckbox"
                name="termcheckbox"
                className={`form-check-input  mx-3 p-2 `}
              />
              <p className='mb-3 fw-500 font-16 text-black'>
                Sign up now to receive app updates, stay informed about upcoming
                events, and get exclusive offers delivered straight to your
                inbox.
              </p>
            </div>
            <div className="d-flex col-12 ">
              <input
                type="checkbox"
                id="termcheckbox"
                name="termcheckbox"
                className={`form-check-input  mx-3 p-2   ${
                  touched.termcheckbox && errors.termcheckbox && "border-danger"
                }`}
                onChange={handleChange}
              />
              <p
                className={`mb-3 fs-6 ${
                  touched.termcheckbox &&
                  errors.termcheckbox &&
                  "text-danger border-danger"
                }`}
              >
                I agree to MOVE’s{" "}
                <Link
                  to="/terms-conditions"
                  className={`auth-font-14  fs-6 fw-500 ${
                    touched.termcheckbox && errors.termcheckbox
                      ? "text-danger"
                      : ""
                  }`}
                  style={{ color: "#010101" }}
                  target="_blank" rel="noopener noreferrer"
                >
                  Terms of Use
                </Link>
                <span
                  className={`auth-font-14 fw-500 mx-1  ${
                    touched.termcheckbox && errors.termcheckbox
                      ? "text-danger"
                      : ""
                  }`}
                >
                  &
                </span>
                <Link
                  to="/privacy-policy"
                  className={`auth-font-14 fw-bold ${
                    touched.termcheckbox && errors.termcheckbox
                      ? "text-danger"
                      : ""
                  }`}
                  style={{ color: "#010101" }}
                  target="_blank" rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
        </form>
      )}}
    </Formik>
  );
};

export default SignupStep2;

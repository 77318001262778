import { Accordion } from "react-bootstrap"
import PageNavigation from "../../Common/PageNavigation/PageNavigation"
import DownloadSection from "../../Common/DownloadSection/DownloadSection"

const FAQ = () => {
    return (
        <>
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="p-lg-5 pb-lg-1 p-0">
                                    <PageNavigation currentPage={"FAQ'S"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container py-4 mb-5">
                        {/* <div className="row mb-2">
                            <div className="col-12">
                                <div className="p-lg-4 pb-lg-2 p-0 text-center">
                                    <span className="bebas-neue-font font-45">
                                        Frequently asked questions
                                    </span>
                                </div>
                            </div>
                        </div> */}
                        <div className="row mb-2">
                            <div className="offset-md-2 col-md-8 col-12">
                                <div className="p-lg-3 pb-lg-1 p-0">
                                    <Accordion className="b-0">
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="0">
                                            <Accordion.Header className="fw-500 font-22 text-101828">How do I register for an event? </Accordion.Header>
                                            <Accordion.Body className="fw-400 font18 font-5E5E5E">
                                                Simply download our app, sign up, and choose the event you want to join.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="1">
                                            <Accordion.Header>Are you available on both Google play store & Apple Store? </Accordion.Header>
                                            <Accordion.Body className="custom-accordion-item border-0">
                                                Yes, we are.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="2">
                                            <Accordion.Header>Does your app support both Android and Ios? </Accordion.Header>
                                            <Accordion.Body className="custom-accordion-item border-0">
                                                Yes, it does.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="3">
                                            <Accordion.Header>How do we set location setting?  </Accordion.Header>
                                            <Accordion.Body className="custom-accordion-item border-0">
                                                Your location setting will be on “Allow all the time” mode in order to get accurate and seamless experience.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="4">
                                            <Accordion.Header>Can I participate in multiple events?  </Accordion.Header>
                                            <Accordion.Body className="custom-accordion-item border-0">
                                                Yes, you can participate in as many events as you like.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="5">
                                            <Accordion.Header>How do I track my progress?  </Accordion.Header>
                                            <Accordion.Body className="custom-accordion-item border-0">
                                                Our app automatically tracks your running and cycling data once you start your activity.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item className="mb-4 custom-accordion-item" eventKey="6">
                                            <Accordion.Header>What kind of rewards do participants receive? </Accordion.Header>
                                            <Accordion.Body className="custom-accordion-item border-0">
                                                Participants receive merchandise like t-shirts and medals upon event registration.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-background">
                    <DownloadSection />
                </section>
            </main>
        </>
    )
}

export default FAQ
import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { useAuth } from "../module/auth/core/Auth";
import AuthPage from "../module/auth";
import { ErrorsPage } from "../module/errors/ErrorsPage";
import Event from "../Components/Event/Event";
import AboutUs from "../Components/AboutUs/AboutUs";
import ContactUs from "../Components/ContactUs/ContactUs";
import React from "react";
import EventDetails from "../Components/EventDetails/EventDetails";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../Components/TermsConditions/TermsConditions";
import Home from "../Components/Home/Home";
import Testimonials from "../Components/Testimonials/Testimonials";
import FAQ from "../Components/FAQ/FAQ";
import Blogs from "../Components/Blogs/Blogs";
import BlogDetails from "../Components/Blogs/BlogDetails";
import PrivateEventDetails from "../Components/EventDetails/PrivateEventDetails";

const AppRoutes: FC = () => {
  const { auth } = useAuth();

  return (
    <>
        <Routes>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          {/* <Route path="/testimonials" element={<Testimonials />} /> */}
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-detail/:slug" element={<BlogDetails />} />
          <Route path="/private-event-detail/:slug/:id" element={<PrivateEventDetails />} />
          <Route index element={<Home />} />
          {auth ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              {/* <Route path="*" element={<Navigate to="/auth" />} /> */}
              <Route index element={<Home />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/events" element={<Event />} />
              <Route path="/eventdetail/:slug" element={<EventDetails />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
    </>
  );
};

export { AppRoutes };

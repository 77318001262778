/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import Card from "../../Common/Card/Card";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { GetAllEvents, GetUpcomingEvents } from "../../services/Web.type";
import CheckboxItem from "../../Common/Dropdown/CheckboxItem";
import SkeletonCard from "../../Common/loader/Loader";
import BlankPage from "../../Common/BlankData/BlankPage";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import { Icon } from "@iconify/react";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import { Link } from "react-router-dom";
import RadioGroupInput from "../../Common/Dropdown/RadioGroupInput";

const Event = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedEventTypes, setSelectedEventTypes] = useState([0]);
  const [selectedEvent, setSelectedEvent] = useState([0]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const pathname = window.location.pathname;

  const options = [
    {
      value: 1,
      label: "Cycling",
      icon: "bx:cycling",
    },
    {
      value: 2,
      label: "Walking",
      icon: "material-symbols:directions-walk",
    },
    {
      value: 3,
      label: "Running",
      icon: "ic:baseline-directions-run",
    }
  ];

  const optionForRadioButton = [
    {
      value: 4,
      label: "UpcomingEvents",
      icon: "",
    },
    {
      value: 5,
      label: "CompletedEvents",
      icon: "",
    }
  ]

  const [isCheckedFor, setIsCheckedFor]: any = useState({
    Cycling: false,
    Walking: false,
    Running: false,
  });
  const [isCheckedForRadio, setIsCheckedForRadio]: any = useState({
    UpcomingEvents: false,
    CompletedEvents: false
  });

  const checkHandlerFor = (value: any) => {
    setIsCheckedFor((prevChecked: any) => ({
      ...prevChecked,
      [value]: !prevChecked[value],
    }));

    setSelectedEventTypes((prevSelected: any) =>
      isCheckedFor[value]
        ? prevSelected.filter((type: any) => type !== value)
        : [...prevSelected, value]
    );
  };

  const checkHandlerForRadio = (value: any) => {
    setIsCheckedForRadio((prevChecked: any) => ({
      ...prevChecked,
      [value]: !prevChecked[value],
    }));

    setSelectedEvent((prevSelected: any) =>
      isCheckedForRadio[value]
        ? prevSelected.filter((type: any) => type !== value)
        : [...prevSelected, value]
    );
  };

  const removeElement = (value: any) => {
    const updatedCheckedState: any = { ...isCheckedFor };
    const updatedCheckedStateRadio: any = { ...isCheckedForRadio };
    updatedCheckedState[value] = false;
    updatedCheckedStateRadio[value] = false;
    setIsCheckedFor(updatedCheckedState);
    setIsCheckedForRadio(updatedCheckedState);

    setSelectedEventTypes((prevEventType) =>
      prevEventType.filter((type) => type !== value)
    );
    setSelectedEvent((prevEventType) =>
      prevEventType.filter((type) => type !== value)
    );
  };

  const getAllEventsQuery = usePostQuery(
    "getAllEventsQuery",
    GetAllEvents,
    {}
  );

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      getAllEventsQuery.mutate({
        search: searchValue,
        eventTypeID: selectedEventTypes,
        isUpcomingEvent: selectedEvent,
        page: page,
        limit: limit,
        isGetAll: true
      });
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchValue, selectedEventTypes, page, limit, selectedEvent]);

  const handleLoadMore = () => {
    const newLimit = limit + 6;
    const pageLimit = page;
    if (getAllEventsQuery?.data?.result?.length > newLimit) {
      setAllDataLoaded(true);
    }
    setPage(pageLimit);
    setLimit(newLimit);
  };

  return (
    <main>
      <div className="upcoming-section-background"></div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="p-lg-5 pb-lg-1 p-0">
                <PageNavigation currentPage={"Events"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ marginTop: "-29px" }}>
          <div className="px-lg-5 pb-lg-1 p-0">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center py-0 mt-5  px-1 fs-2 fw-bold text-default-color ">
                  <InputGroup className="mb-3  border border-1 rounded-pill p-2 search-box-shadow  bg-white ">
                    <InputGroup.Text className="bg-white border-0 d-none d-lg-inline-flex">
                      {/* hide mobile device */}
                      <Icon
                        icon="material-symbols:search"
                        className="text-secondary search-icon-font-size"
                      />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search events"
                      aria-describedby="basic-addon1"
                      className="border-0 search-input "
                      style={{ boxShadow: "none" }}
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                    <InputGroup.Text className="bg-white border-0 d-none d-lg-inline-flex">
                      <Row>
                        {options.map(
                          ({ value, label, icon }) =>
                            isCheckedFor[value] && (
                              <div
                                className="col-auto"
                                key={value}
                                style={{ marginRight: "-15px" }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm px-2  p-2 border-0 text-dark"
                                  style={{
                                    borderRadius: "100px",
                                  }}
                                >
                                  {" "}
                                  <Icon
                                    icon={icon}
                                    className="search-icon-font-size"
                                    style={{ marginTop: "-3px" }}
                                  />
                                  <span className="text-uppercase fw-500 me-1">
                                    {label}
                                  </span>
                                  <Icon
                                    icon="carbon:close"
                                    className="search-icon-font-size"
                                    style={{ marginTop: "-3px" }}
                                    onClick={() => removeElement(value)}
                                  />
                                </button>
                              </div>
                            )
                        )}
                        {optionForRadioButton.map(
                          ({ value, label, icon }) =>
                            isCheckedForRadio[value] && (
                              <div
                                className="col-auto"
                                key={value}
                                style={{ marginRight: "-15px" }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-light btn-sm px-2  p-2 border-0 text-dark"
                                  style={{
                                    borderRadius: "100px",
                                  }}
                                >
                                  {" "}
                                  <Icon
                                    icon={icon}
                                    className="search-icon-font-size"
                                    style={{ marginTop: "-3px" }}
                                  />
                                  <span className="text-uppercase fw-500 me-1">
                                    {label}
                                  </span>
                                  <Icon
                                    icon="carbon:close"
                                    className="search-icon-font-size"
                                    style={{ marginTop: "-3px" }}
                                    onClick={() => removeElement(value)}
                                  />
                                </button>
                              </div>
                            )
                        )}
                      </Row>
                    </InputGroup.Text>
                    {/* hide mobile device */}
                    <InputGroup.Text className="bg-white  border-0  fw-500 text-secondary d-none d-lg-inline-flex">
                      <Icon
                        icon="vaadin:line-v"
                        className="text-secondary me-3 search-icon-font-size"
                      />
                      <span className="text-secondary">FILTER</span>
                    </InputGroup.Text>
                    <Dropdown autoClose="outside" align={"end"}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="bg-white border-0 search-dropdown "
                      >
                        <Icon
                          icon="material-symbols:instant-mix-rounded"
                          className="text-secondary search-icon-font-size"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="rounded-4 p-2 mt-4 search-box-shadow border-0"
                        style={{ zIndex: "10" }}
                      >
                        {optionForRadioButton.map(({ value, label, icon }) => (
                          <CheckboxItem
                            key={value}
                            id={value}
                            isChecked={isCheckedForRadio[value]}
                            checkHandler={() => checkHandlerForRadio(value)}
                            label={label}
                            icon={icon}
                          />
                        ))}
                        <hr />
                        {options.map(({ value, label, icon }) => (
                          <CheckboxItem
                            key={value}
                            id={value}
                            isChecked={isCheckedFor[value]}
                            checkHandler={() => checkHandlerFor(value)}
                            label={label}
                            icon={icon}
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                  {/* show mobile device */}
                </div>
              </div>
              {/* show mobile device */}
              <Row className="d-lg-none " style={{ marginLeft: "5px" }}>
                {options.map(
                  ({ value, label, icon }) =>
                    isCheckedFor[value] && (
                      <div
                        className="col-auto"
                        key={value}
                        style={{ marginRight: "-15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-sm mt-2 p-2 default-background-color  border-0 text-black"
                          style={{
                            borderRadius: "100px",
                          }}
                        >
                          {" "}
                          <Icon
                            icon={icon}
                            className="search-icon-font-size"
                            style={{ marginTop: "-3px" }}
                          />
                          <span className="text-uppercase fw-500 me-1">
                            {label}
                          </span>
                          <Icon
                            icon="carbon:close"
                            className="search-icon-font-size"
                            style={{ marginTop: "-3px" }}
                            onClick={() => removeElement(value)}
                          />
                        </button>
                      </div>
                    )
                )}
                {optionForRadioButton.map(
                  ({ value, label, icon }) =>
                    isCheckedForRadio[value] && (
                      <div
                        className="col-auto"
                        key={value}
                        style={{ marginRight: "-15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-sm mt-2 p-2 default-background-color  border-0 text-black"
                          style={{
                            borderRadius: "100px",
                          }}
                        >
                          {" "}
                          <Icon
                            icon={icon}
                            className="search-icon-font-size"
                            style={{ marginTop: "-3px" }}
                          />
                          <span className="text-uppercase fw-500 me-1">
                            {label}
                          </span>
                          <Icon
                            icon="carbon:close"
                            className="search-icon-font-size"
                            style={{ marginTop: "-3px" }}
                            onClick={() => removeElement(value)}
                          />
                        </button>
                      </div>
                    )
                )}
              </Row>
            </div>
          </div>
        </div>
      </section>
      <section className="event-margin">
        <Container>
          <div className="px-lg-5 pb-lg-1 p-0">
            <Row className="justify-content-center">
              {getAllEventsQuery?.data?.result?.map((item: any) => {
                return (<>
                  <div className="col-md-6 col-xl-4 mb-4 mt-2 move-up">
                    <Card key={item.id} item={item} pageName={"Events"} isdetailpage={true} />
                  </div>
                </>)
              })}
              <SkeletonCard
                pathname={pathname}
                getAllEventsQuery={getAllEventsQuery}
              />
              {getAllEventsQuery?.data?.result?.length === 0 && <BlankPage />}
            </Row>

            {!allDataLoaded &&
              getAllEventsQuery?.data?.result?.length >= limit && (
                <Row className="justify-content-center">
                  <div className="col-12">
                    <div className="button-container">
                      <hr
                        className="hr-text"
                        data-content="Load More"
                        onClick={handleLoadMore}
                      />
                    </div>
                  </div>
                </Row>
              )}
          </div>
        </Container>
      </section>
      <section className="section-background">
        <DownloadSection />
      </section>
    </main>
  );
};

export default Event;

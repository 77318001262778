import React, { useState } from "react";
import { Col, Image, Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { cropImage } from "./cropUtils";
import toastify from "../../helper/toastify";
import { USER_DEFAULT_IMAGE_PATH } from "../../shared/constants";
import { toAbsoluteUrl } from "../../helper";

const ImageUpload = (props: any) => {
  const {
    containerStyle,
    onComplete,
    showCropperModal,
    setShowCropperModal,
    imagesrc,
  } = props;
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropChange = (crop: any) => {
    setCrop(crop);
  };

  const handleZoomChange = (zoom: any) => {
    setZoom(zoom);
  };

  const onCancel = () => {
    setShowCropperModal(false);
  };

  const handleCropImage = async () => {
    if (onComplete && croppedAreaPixels) {
      try {
        const croppedImageData = await cropImage(imagesrc, croppedAreaPixels);
        if (croppedImageData) {
          onComplete(croppedImageData);
        } else {
          // Handle the case when croppedImageData is undefined
          console.error("Cropped image data is undefined");
        }
      } catch (error) {
        // Handle any errors that occur during cropping
        console.error("Error cropping image:", error);
      } finally {
        onCancel();
      }
    }
  };

  return (
    <Col>
      <div className="card mb-4 border-0">
        <div className="card-body text-center">
          <Modal
            show={showCropperModal}
            onHide={() => setShowCropperModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title> Crop Profile Image </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div style={{ width: "100%" }}>
                  <div style={containerStyle}>
                    <Cropper
                      image={imagesrc}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={handleCropChange}
                      onZoomChange={handleZoomChange}
                      onCropComplete={onCropComplete}
                      cropSize={{ width: 150, height: 150 }}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {/* {imagesrc && ( */}
              <button onClick={handleCropImage} className="border rounded-pill fs-5 px-3 py-2 btnNavLink  btn-getapp text-dark plus-jakarta-sans-font btn btn-primary">
                Crop Image
              </button>
              {/* )} */}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Col>
  );
};

export default ImageUpload;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import { Link } from "react-router-dom";
import { WEB_ENDPOINT } from "../../shared/apiConfig";

const TermsConditions: React.FC = () => {
  return (
    <main>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="p-lg-5 pb-lg-1 p-0">
                <PageNavigation currentPage={"Terms & Conditions"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="px-md-5 pb-md-1 p-0">
            <div className="row">
              <div className="col-md-12">
                <p className="border-E6E6E6 line-height-25-2  fw-400 m-0 font-22">
                  Last Updated : 16/08/2024
                </p>
              </div>
              <div className="col-md-12">
                <p className="font-22 border-bottom  border-E6E6E6 pb-4 pt-2 line-height-25-2 font-000000 fw-400 text-justify">
                  Welcome to Move! By using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="px-md-5 pb-md-1 p-0">
            <div className="row">
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 ">
                  1. Agreement to Terms
                </p>
                <p className="fw-400 lh-27 font-20 text-justify">
                  This Terms of Use Agreement ("Agreement") establishes the legally binding terms for your use of  <Link
                    to={`${WEB_ENDPOINT}`}
                    target="_blank"
                  >
                    WWW.IAMONTHEMOVE.COM
                  </Link> (hereinafter referred to as "MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM"). You may use the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM website only if you agree to comply with all applicable laws and this Agreement. Please read this Agreement carefully before using the website. By registering on, accessing, browsing, downloading, or using the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM website for any purpose, you automatically and immediately agree to be bound by this Agreement, as well as any service-specific terms and conditions applicable to each Service, whether you are a “Visitor” (simply browsing the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM site) or a “Member” (registered with MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM). The term “User” refers to a Visitor or a Member. Subject to your compliance with this Agreement, you may print or save a copy of these terms of use for your personal and non-commercial use. If you do not agree with any of these terms, you must immediately cease accessing, browsing, or using the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM site and terminate your use of MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM Services. If you wish to become a Member, communicate with other Members, and utilize MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM Services, you must read and accept this Agreement during the registration process.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600  font-22 line-height-25-2 font-000000 ">
                  2. Authorized User
                </p>
                <p className="fw-400 font-20 line-height-25-2 text-justify">
                  An “authorized user” includes any person, whether an individual or a legal entity, who has subscribed to the services of MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, and to whom access is restricted by a sign-in username and password. The username and password may be allocated by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, chosen by the user, or agreed upon by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM.
                </p>
                <p className="fw-400 lh-27 font-20 text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not responsible for any costs, consequences, or damages caused to any persons or entities due to unauthorized use of the services offered by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, including access by an unauthorized user using the username and password of an authorized user, regardless of whether such unauthorized user is known to the authorized user.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600  font-22 line-height-25-2 font-000000 ">
                  3. Services
                </p>
                <p className="fw-400 font-20 line-height-25-2 font-000000 text-justify">
                  The terms “service” or “services” refer to the interactive online solutions and information services offered by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM through which the user may access information maintained in MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM's database.
                  Users agree not to copy, sell, resell, or duplicate any part of MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM services and solutions for commercial purposes.

                </p>
              </div>
              <div className="col-12">
                <p className="fw-600  font-22 line-height-25-2 font-000000 ">
                  4. Modifications to Terms of Service
                </p>
                <p className="fw-400  font-20 line-height-25-2  text-justify">
                  These Terms of Service outline the legally binding terms of your use of MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM and your membership in MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM and may be modified by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM at any time without prior notice, with such modifications becoming effective upon posting on the website. Your continued access or use of the Site and/or the Services after the Terms of Service have been revised constitutes your express consent to the modified Terms of Service. In the event of any inconsistency between our Privacy Policy and these Terms of Service, the Terms of Service shall prevail. If you do not agree to be bound by this Agreement, you may not use or access our services.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600  font-22 line-height-25-2 font-000000 ">
                  5. Eligibility
                </p>
                <p className="fw-400  font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM services are available to all individuals who are at least 13 years of age. Individuals under the age of 13 may access and use the site only with the involvement and guidance of their parents or legal guardians, who will be held responsible for all the terms of this agreement. By registering with MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, you consent to the use of electronic means to complete the Terms and to provide you with any notices given pursuant to the Terms, and electronic records to store information related to the Terms or your use of the Services. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM reserves the right to suspend or terminate your use of MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM services and refuse any current or future use of any portion of MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM’s services at its sole discretion.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  6. Services Provided
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM provides a platform for hosting virtual fitness events across the globe. The services offered by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM include:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ol>
                    <li>
                      Event Hosting: Event organizers can organize and host their events, view statistics on user registrations, and data submitted.
                    </li>
                    <li>
                      Event Viewing and Registration: Registered members can view different events hosted on the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM website and register for events. Event-specific terms, including event fees and rules, will be mentioned in the event details section.
                    </li>
                    <li>Event Participation:
                      <ul>
                        <li>Data Submission: To participate in any fitness event, the registered member must submit their activity data automatically.</li>
                        <li>Flag Runs: Registered members can flag suspicious runs from other users in certain events.</li>
                        <li>Event Reports: Unless specified, all event results are published as reports on MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, which are publicly available.</li>
                      </ul>
                    </li>
                  </ol>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  7. Attending Events
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not legally responsible or liable for:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>Cancellation or postponement of events by the organizers (if MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not the event organizer).</li>
                    <li>Any damage or injury caused by attending events.</li>
                    <li>Providing hydration or medical support during events.</li>
                    <li>Participants not taking necessary precautions for COVID-19.</li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  8. Shipment & Delivery
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  If a member registers for an event that includes gratifications like medals or T-shirts, the following policy applies:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>Details of gratifications and eligibility criteria will be available on the specific event details page.</li>
                    <li>Information such as gender, size, and delivery address is captured at registration and can be updated in the profile section.</li>
                    <li>
                      Size and address changes can be made before shipment. If changes are needed after shipment, the participant/member must bear the cost of reshipment. For such changes, contact support@iamonthemove.com , www.iamonthemove.com.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  9. Payment and Fees
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>Event Hosting: Organizers can contact sales@iamonthemove.com , www.iamonthemove.com.com to get the transactional cost of organizing an event. The cost varies based on multiple factors.</li>
                    <li>Registration and Profile Creation: Registration and profile creation on MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is free of charge.</li>
                    <li>Event Catalogues: Event catalogues are publicly available for free.</li>
                    <li>Event Participation: Participation in events is chargeable. Each event will have its participation fee publicized. Discounts and applicable taxes will be clearly mentioned on the registration page and in the payment receipt.</li>
                    <li>Event Reports: Event reports are publicly available unless otherwise specified.</li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  10. Payment Processing
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM uses razorpay  for payment gateway services and does not request or maintain any database of participants' financial information.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  11. Warranty of the Organizer
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  For event hosting services, the organizer warrants to MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM that:
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  <ul>
                    <li>There is no agreement with a venue, venue owner/user, or third party that affects the organizer’s use of the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM website in accordance with these terms.</li>
                    <li>The organizer is authorized to enter into this agreement with MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, including authorizing MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM to act as an agent and sell tickets for the organizer's events.</li>
                    <li>The organizer has obtained all necessary approvals for organizing the event within the chosen region and will inform MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM immediately of any changes that affect the event. The organizer will indemnify MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM for any losses arising from failure to obtain necessary approvals.</li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  12. Duties of the Organizer
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  The organizer must:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>Immediately inform MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM about any unavailability or malfunctions of the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM website.</li>
                    <li>Notify MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM promptly of any changes to their name, company name, address, legal form, or bank details in writing or via email.</li>
                    <li>Refrain from using MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM for illegal or immoral events, including those promoting political or religious extremism, pornography, or violence.</li>
                  </ul>
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  The organizer must not:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>
                      Upload, post, email, transmit, or make available any unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically, or otherwise objectionable content.
                    </li>
                    <li>
                      Promote sexually explicit or suggestive content, drugs, or prohibited substances.
                    </li>
                    <li>
                      Harm minors in any way.
                    </li>
                    <li>
                      Impersonate any person or entity, including an MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM representative.
                    </li>
                    <li>
                      Forge headers or manipulate identifiers to disguise the origin of information transmitted through the Service.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  13. Right to Terminate
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, in its sole discretion and without penalty, may terminate this agreement for any or no reason. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM may also discontinue providing access to the services if the organizer:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>Violates significant provisions of these terms.</li>
                    <li>Files for bankruptcy or insolvency proceedings, or such proceedings are dismissed due to insufficient assets.</li>
                    <li>Cannot fulfil contractual obligations due to insufficient assets and fails to prove sufficient assets within 30 days after a request by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM.</li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  14. Ticket Buyer Terms
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>The registration fee for events is as stated on the event details page.</li>
                    <li>Event registration is confirmed immediately upon successful payment. In case of discrepancies or delays in confirmation, contact support@iamonthemove.com , www.iamonthemove.com.com within 24 hours.</li>
                    <li>Confirmation of payment and event registration is also sent to the registered email address. Participants must ensure their email address is correct.</li>
                    <li>Event participation is subject to the Rules and Regulations of the Event, detailed on the event details section. Breach of these rules or unacceptable behavior may result in ejection from the event.</li>
                    <li>Event registrations may be limited per person, credit card, or household. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM reserves the right to cancel tickets exceeding these limits.</li>
                    <li>By agreeing to MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM’s terms, you also agree to razorpay's terms and conditions. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not liable for how razorpay handles data.</li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 my-3 font-24 line-height-25-2 font-000000 ">
                  General Terms
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  1. Advertising Material
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not liable for any inappropriateness in advertisement content due to the dynamic nature of the web. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM reserves the right to remove or reposition advertising material.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  2. Safe Harbour
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Any forward-looking statements on the Site regarding MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM’s future prospects involve risks, uncertainties, and other factors that could cause actual results to differ materially from projections.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  3. Intellectual Property Rights
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ul>
                    <li>
                      MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM owns all rights to the code, databases, visual design, and layout of the Service. Except for the limited licenses expressly granted, no other rights are granted to you under this Agreement.
                    </li>
                    <li>
                      You may access the site solely as intended through the provided functionality of the Service and as permitted under this Agreement.
                    </li>
                    <li>
                      You may not use any automated devices or manual processes to monitor or copy any content from the Service without MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM's written consent.
                    </li>
                    <li>
                      You may not resell, duplicate, reproduce, or exploit any part of the Service without MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM's written permission.
                    </li>
                    <li>
                      You may not alter or bypass any approved software through which the Services are made available or interfere with any security features of the site.
                    </li>
                    <li>
                      You agree not to undertake or permit reverse engineering, disassembling, or hacking of any aspect of the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM Service, or attempt to do so.
                    </li>
                    <li>
                      You agree not to harass, harm, or incite harassment or harm of another person or group, including MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM employees and users.
                    </li>
                    <li>
                      You agree not to provide false personal information, create a false identity, or impersonate another person or entity.
                    </li>
                    <li>
                      You agree not to solicit personal information from other users or restrict or discourage anyone from using MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM.
                    </li>
                    <li>
                      You agree not to gain unauthorized access to MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM websites or users’ accounts, or post or transmit any harmful code.
                    </li>
                    <li>
                      You agree not to interfere with MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM websites or violate regulations of connected networks or servers.
                    </li>
                    <li>
                      You agree not to violate any laws or this Agreement, or assist others in doing so.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  4. Information Disclosure
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM may collect, use, and disclose personal information as required or permitted by law, in connection with security or law enforcement investigations, or in compliance with legal requirements.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  5. Disclaimer of Warranties
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  You acknowledge and agree that MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM services are provided on an "AS IS" and "AS AVAILABLE" basis. To the fullest extent permitted by law, MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM excludes all representations and warranties related to the website and its contents, including any inaccuracies or omissions. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not liable for damages resulting from or related to your use of the website. We do not guarantee that our website will operate error-free or be free of computer viruses or other harmful components. The content on the website is for general informational purposes only and does not constitute advice. Any specific advice or responses on the website are personal opinions and not endorsed by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM. We assume no responsibility for the accuracy or existence of communications between users. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM expressly disclaims all warranties, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. This includes, without limitation, direct losses, business losses, loss of profits, damage to your computer or data, or any other direct or indirect, consequential, or incidental damages.
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM makes no warranty that the services will meet your requirements or be uninterrupted, timely, secure, or error-free. Certain links on the website lead to third-party resources over which MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM has no control. By visiting these external sites, you understand that you are leaving the MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM website and acknowledge that MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM does not endorse or take responsibility for the authenticity or availability of goods/services, or any harm or legal violations incurred from these sites. Exercise caution and common sense when using the Service.
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Any material downloaded through the website is at your own risk, and you are solely responsible for any damage to your computer system or data loss resulting from such downloads.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  6. Limitation of Liability
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  You agree that MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM shall not be liable for any consequential, incidental, indirect, special, punitive, or other damages, including loss of business profits, business interruptions, computer failures, loss of business information, or any other loss arising from your use or inability to use the website, even if MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM has been advised of the possibility of such damages. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM will not be responsible for any business losses (including loss of profits, revenue, contracts, anticipated savings, data, goodwill, or wasted expenditures) or any other indirect or consequential losses that are not reasonably foreseeable when you started using the website. Service interruptions may occur for reasons beyond MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM's control, and damages from such interruptions are difficult to quantify. Therefore, MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not liable for damages from causes beyond its direct and exclusive control. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM’s liability for negligence will not exceed the amount equivalent to the charges you paid for the services during the period the damages occurred. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM shall not be liable for any special or consequential damages. This limitation of liability will survive the termination of this Agreement.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  7. Indemnity
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  You agree to defend, indemnify, and hold harmless MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, its subsidiaries, affiliates, and their respective directors, officers, agents, contractors, partners, and employees from any loss, liability, claim, demand, damages, costs, and expenses, including reasonable attorney’s fees, arising from or related to any User Content, Third-Party Applications, software, or content you post or share on the website, your use of the website, your conduct in relation to the website or other users, or any violation of this Agreement or applicable laws. MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM reserves the right to assume exclusive defence and control of any matter for which you are required to indemnify MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, including settlement rights, and you agree to cooperate with MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM in its defence and settlement. You expressly release MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM and its affiliates from any costs, damages, liabilities, or consequences related to the actions or inactions of our partners or sellers, and waive any claims you may have under any statute or contract. This indemnity provision will survive the termination of this Agreement.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  8. Force Majeure
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM shall not be liable for any failure to perform obligations under this Agreement due to events beyond its control, including but not limited to natural disasters, terrorism, war, political unrest, riots, civil disturbances, acts of authority, or other unforeseeable events. If affected by such an event, MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM will notify you through the website and make reasonable efforts to comply with the terms of any Agreement.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  9. Notice & Take Down Policy
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  As an intermediary, MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not liable for any third-party content uploaded, transmitted, displayed, published, or shared on its websites/app/SMS/ Social media, whatapp. By using MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM, you agree to the conditions outlined in this Notice and Take Down Policy.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-500 font-22 line-height-25-2 font-000000 ">
                  10. Copyright Infringement Notification
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  If you believe that copyright-protected work on MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM.com is infringing your copyright, you may submit a copyright infringement notification.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2">
                  11. Procedure:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ol>
                    <li>
                      <span className="fw-600 font-20">Contact Information: </span>Provide contact details allowing MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM and the uploader to communicate, such as an email address or phone number.
                    </li>
                    <li>
                      <span className="fw-600 font-20">Description of Work: </span>Provide a detailed description of the copyrighted content, including the specific URL of the allegedly infringing content. General information alone is insufficient.
                    </li>
                    <li>
                      <span className="fw-600 font-20">Necessary Format: </span>Include statements that you have a good faith belief that the material is unauthorized and that the information is accurate.
                    </li>
                    <li>
                      <span className="fw-600 font-20">Signature: </span>Include a physical or electronic signature of the copyright owner.
                    </li>
                  </ol>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Take-Down of Infringing Work: If a valid complaint is received, MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM may remove or block access to the infringing work for 21 days or until a court order is received. If no court order is received, access to the work may be reinstated.
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Notification of Objectionable Content and Take Down by MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Users may report objectionable content by submitting a written complaint.
                </p>
                <p className="fw-600 font-20 line-height-25-2  ">
                  Procedure:
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  <ol>
                    <li>
                      <span className="fw-600 font-20 text-justify">Contact Information:</span> Provide details allowing MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM to contact you.
                    </li>
                    <li>
                      <span className="fw-600 font-20 text-justify">Description of Work: </span>Describe the objectionable content and provide the specific URL.
                    </li>
                    <li>
                      <span className="fw-600 font-20 text-justify">Necessary Format: </span>State that you believe the content violates the law or MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM’s policies.
                    </li>
                  </ol>
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM reserves the right to take down or block access to content that violates its policies or applicable laws.
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM Immunity from Liability: As an intermediary, MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM is not liable for copyright infringement or objectionable content as provided under relevant laws.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2">
                  12. Refund and Cancellation Policy
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  If you believe that copyright-protected work on MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM.com is infringing your copyright, you may submit a copyright infringement notification.
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Participation in an event may be cancelled up to 24 hours before the event's scheduled start time. In such cases, the registration fee will be refunded to the participant. To request a cancellation or refund, participants must send a written request to support@iamonthemove.com
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  Refunds are subject to specific conditions and event-related terms. The following refund scenarios apply:
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  <ol>
                    <li>
                      No fees will be refunded once you are registered
                    </li>
                    <li>
                      Registrations are not transferable
                    </li>
                    <li>
                      In case if Move Wellness Solutions decides to cancel the event due to unforeseen circumstances refund shall be initiated within 5-7 working days
                    </li>
                  </ol>
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-20 line-height-25-2 text-justify">
                  Note:  <span className="fw-400 font-20 line-height-25-2 text-justify">Each event may have its own specific refund policy. If there is a discrepancy between the event-specific refund policy and the general policy outlined here, the event-specific policy will take precedence. Participants are advised to review the Terms & Conditions of each event before purchasing tickets.</span>
                </p>
                <p className="fw-400 font-20 line-height-25-2 text-justify">
                  In the event of unforeseen circumstances that lead to the cancellation, postponement, suspension, or delay of an event, MOVE WELLNESS SOLUTIONS, WWW.IAMONTHEMOVE.COM will announce the changes via digital media, email, SMS, etc., and will outline the applicable refund policy.
                </p>
                <p className="fw-400 font-20 line-height-25-2 text-justify">
                  For events hosted by other organizers, registrations are governed by the Rules and Regulations set by the Event Organiser. Full details are available from the Event Organiser. Any breach of these Rules and Regulations, or any behaviour likely to cause damage, nuisance, or injury, may result in ejection from the venue by MOVE WELLNESS SOLUTIONS, WWW.IAMONTHEMOVE.COM or the Event Organiser.
                </p>
                <p className="fw-400 font-20 line-height-25-2 text-justify">
                  Registrations may be limited per person, per credit card, and, in some cases, per household. MOVE WELLNESS SOLUTIONS, WWW.IAMONTHEMOVE.COM reserves the right to cancel tickets purchased in excess of these limits without prior notice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container-fluid">
          <div className="border-top border-E6E6E6 d-flex justify-content-center">
            <div className="row my-4">
              <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                <input
                  type="button"
                  value={"Accept"}
                  className="btn btn-outline-warning rounded-pill font-010101 p-2 px-5 fs-5 fw-500 my-2 border border-dark  text-dark"
                />
              </div>
              <div className="col-lg-6 col-md-6 d-flex justify-content-center">
                <input
                  type="button"
                  value={"Decline"}
                  className="btn btn-outline-warning rounded-pill font-010101 p-2 px-5 fs-5 fw-500 my-2 border border-dark text-dark"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
};
export default TermsConditions;

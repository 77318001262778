import { Icon } from "@iconify/react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { WEB_ENDPOINT } from "../../shared/apiConfig";

const Footer = () => {
  return (
    <>
      <Container fluid className="bg-rgba-footer">
        <Container>
          <footer className="py-md-5 py-3">
            <div className="row d-flex justify-content-between">
              <div className="col-md-5 col-12 mb-md-3 mb-4">
                <Row>
                  <Link className="active navbar-brand" to="/">
                    <div className="text-default-color fw-bold bebas-neue-font mb-md-3 mb-1">
                      <img
                        src={`${WEB_ENDPOINT}/Move-logo-BG-transparent.svg`}
                        className="img-fluid"
                        alt="logo"
                        width={151}
                      />
                    </div>
                  </Link>
                  <p className="font-16 font-5E5E5E fw-400 lh-card text-justify">
                    Stay connected and make the most of your fitness event experience with our official app! Get access to exclusive features, real-time updates, and more, right at your fingertips.
                  </p>
                </Row>
                <Row>
                  <Col className="col-12">
                    <div className="d-flex justify-content-start pt-3">
                      <h3 className="fw-500 text-uppercase text-center font-26 lh-27 text-black mb-1">
                        Download the MOVE app
                      </h3>
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="d-flex flex-row gap-3 pt-2 pb-0">
                      <Link
                        to="https://play.google.com/store/apps?hl=en&gl=US"
                        target="_blank"
                      >
                        <img
                          src="\media\assets\events\google-play.png"
                          alt="google-play"
                          className="img-fluid"
                        />
                      </Link>
                      <Link
                        to="https://www.apple.com/in/app-store/"
                        target="_blank"
                      >
                        <img
                          src="\media\assets\events\app-store.png"
                          alt="apple-store"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className=" col-md-2  col-12 mb-3">
                <div className="fw-500 text-uppercase font-26 lh-27">
                  QUICK ACCESS
                </div>
                <ul className="nav flex-column pt-md-2 pb-0">
                  <li className="nav-item mb-2">
                    <Link
                      to="/"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/about-us"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/events"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      Events
                    </Link>
                  </li>
                  {/* <li className="nav-item mb-2">
                    <Link
                      to="/testimonials"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      Testimonial
                    </Link>
                  </li> */}
                  <li className="nav-item mb-2">
                    <Link
                      to="/blogs"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/faq"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      Faq
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/contact-us"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                    >
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/privacy-policy"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                      target="_blank" rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      to="/terms-conditions"
                      className="text-decoration-none p-0 text-5F6368 fw-400 font-16"
                      target="_blank" rel="noopener noreferrer"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-4 col-12 mb-3">
                <div className="row">
                  <div className="fw-500 text-uppercase font-26 lh-27 pb-1">
                    contact
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex">
                    <div className="pe-3">
                      <Icon icon="gis:poi-map" color="rgba(118, 118, 118, 1)" fontSize={19}></Icon>
                    </div>
                    <div>
                      <p className="p-0 text-5F6368 mb-0 fw-400 font-16">
                        Move Wellness Solutions
                      </p>
                      <p className="text-5F6368 fw-400 mb-0 font-16">
                        127 Zenon Building, Opp. Unique Hospitals, Bamroli Road, Surat, 395002.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="pe-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        fill="rgba(118, 118, 118, 1)"
                        className="bi bi-telephone-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="p-0 text-5F6368 mb-0 fw-400 font-16 pt-1">
                        +91 9825640664
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex align-items-center">
                    <div className="pe-3">
                      <svg xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none">
                        <path d="M2.33317 14.2576C1.87484 14.2576 1.48248 14.0945 1.15609 13.7681C0.829698 13.4417 0.666504 13.0493 0.666504 12.591V2.59098C0.666504 2.13265 0.829698 1.74029 1.15609 1.4139C1.48248 1.08751 1.87484 0.924316 2.33317 0.924316H15.6665C16.1248 0.924316 16.5172 1.08751 16.8436 1.4139C17.17 1.74029 17.3332 2.13265 17.3332 2.59098V12.591C17.3332 13.0493 17.17 13.4417 16.8436 13.7681C16.5172 14.0945 16.1248 14.2576 15.6665 14.2576H2.33317ZM8.99984 8.42432L15.6665 4.25765V2.59098L8.99984 6.75765L2.33317 2.59098V4.25765L8.99984 8.42432Z" fill="#767676" />
                      </svg>
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="p-0 text-5F6368 fw-400 font-16 text-decoration-none"
                      >
                        info.iamonthemove@gmail.com
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="row mt-4">
                  <div className="fw-500 text-uppercase font-26">
                    Follow us
                  </div>
                  <div className="fw-400 font-16 text-5F6368">
                    Stay connected with Move on social media for the latest updates, fitness tips, and communit highlights.
                  </div>
                </div> */}
                {/* <div className="row mt-2">
                  <div className="d-flex">
                    <div className="pe-4">
                      <Link
                        to="#"
                        style={{ color: "#5E5E5E", textDecoration: "none" }}
                      // target="_blank" rel="noopener noreferrer"
                      >
                        <img src="/media/assets/svg/instagram.svg" height={40} alt="instagram" className="img-fluid" />
                      </Link>
                    </div>
                    <div className="pe-4">
                      <Link
                        to="#"
                        style={{ color: "#5E5E5E", textDecoration: "none" }}
                      target="_blank" rel="noopener noreferrer"
                      >
                        <img src="/media/assets/svg/x.svg" height={40} alt="twitter" className="img-fluid" />
                      </Link>

                    </div>
                    <div className="pe-4">
                      <Link
                        to="#"
                        style={{ color: "#5E5E5E", textDecoration: "none" }}
                      target="_blank" rel="noopener noreferrer"
                      >
                        <img src="/media/assets/svg/linkedin 1.svg" height={40} alt="linked-in" className="img-fluid" />
                      </Link>
                    </div>
                    <div className="pe-4">
                      <Link
                        to="#"
                        style={{ color: "#5E5E5E", textDecoration: "none" }}
                      target="_blank" rel="noopener noreferrer"
                      >
                        <img src="/media/assets/svg/facebook.svg" height={40} alt="facebook" className="img-fluid" />
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </footer>
        </Container>
      </Container>

      <Container fluid className="px-0 shadow-on-top">
        <div className="py-4 px-md-5 px-3 border-top">
          <div className="row">
            <div className="col-md-4">
              <p className="mb-0 fw-400 font-18">
                <span className="text-5F6368">Copyrights@{new Date().getFullYear()} • <span className="default-text-color">MOVE</span></span>
              </p>
            </div>
            <div className="col-md-4 d-md-flex  justify-content-center">
              <p className="mb-0 fw-400 font-18">
                <span>
                  Developed By : <Link
                  to="https://engrossinfotech.com/"
                  style={{ color: "#5E5E5E", textDecoration: "none" }}
                  target="_blank" rel="noopener noreferrer"
                >
                  Engross Infotech
                </Link>
                </span>
              </p>
            </div>
            <div className="col-md-4 d-md-flex  justify-content-end ">
              <div className="d-flex fw-400 font-5E5E5E font-18">
                <Link
                  to="/privacy-policy"
                  style={{ color: "#5E5E5E", textDecoration: "none" }}
                  target="_blank" rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                <span className="px-2" style={{ color: "#5E5E5E" }}>|</span>
                <Link
                  to="/terms-conditions"
                  style={{ color: "#5E5E5E", textDecoration: "none" }}
                  target="_blank" rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;

const FourthBlog = () => {
  return (
    <>
      <div className="row pb-md-4">
        <div className="col-12">
          <span className="fw-d-din-700 font-42">
            How Our Running and Cycling Challenges Benefit Individual
            Participants
          </span>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-md-8 col-xl-6">
          <div className="row">
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="fw-500 text-9BA1A2 font-20">
                  <span>Written By</span>
                </div>
                <div>
                  <span className="pe-2">
                  <img src="/media/assets/user/blog-user-image.jpeg" alt="blog-profile" width={30} className="rounded-4" />
                  </span>
                  <span className="fw-600 font-20 text-111827">
                  Mehul Adajaniya
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div>
                  <span className="font-20 text-9BA1A2 fw-500">Publish On</span>
                </div>
                <div>
                  <span className="font-20 text-111827 fw-600">
                  4 Jun 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-4">
        <div className="d-flex justify-content-center">
          <img
            src="/media/assets/blogs/blog_4.png"
            className="img-fluid"
            alt="blogimage-4"
          />
        </div>
      </div>
      <div className="row text-9BA1A2 px-md-3">
        <p className="font-18 fw-400">Hello everyone,</p>
        <p className="font-18 fw-400 text-justify">
          I’m Mehul Adajaniya, founder of Move Wellness Solutions. At Move,
          we’re passionate about helping individuals lead healthier, more active
          lives through our running and cycling challenges. Whether you’re a
          seasoned athlete or just starting your fitness journey, our challenges
          offer numerous benefits that can positively impact your physical and
          mental well-being. Let’s explore how participating in our 100-day,
          monthly, and yearly challenges can transform your life.
        </p>
      </div>
      <div className="row mb-2 px-md-3">
        <ol className="list-group list-group-numbered border-0 fw-500 font-25 ">
          <li className="list-group-item d-flex justify-content-between align-items-start border-0 ">
            <div className="ms-2 me-auto">
              <div className="">Building Consistency and Discipline</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                One of the biggest hurdles in maintaining a fitness routine is
                consistency. Our challenges are designed to help you build and
                maintain regular exercise habits. Whether it’s a 100-day
                challenge or a year-long commitment, having a structured goal
                keeps you motivated and disciplined. The regularity of these
                challenges ingrains a sense of routine, making physical activity
                a natural part of your daily life.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Setting and Achieving Goals</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Setting goals is crucial for any fitness journey. Our challenges
                provide clear, achievable objectives that keep you focused and
                motivated. Whether it’s running a certain distance each month or
                cycling a set number of miles over a year, having specific
                targets helps you measure your progress and celebrate your
                achievements. Reaching these goals boosts your confidence and
                encourages you to set even higher aspirations.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Improving Physical Health</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Regular participation in running and cycling has numerous health
                benefits. These activities improve cardiovascular health,
                strengthen muscles, enhance joint flexibility, and boost lung
                capacity. Engaging in our challenges ensures that you get
                consistent exercise, leading to better overall physical health.
                Over time, you’ll notice improvements in your endurance,
                strength, and energy levels.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Mental Health Benefits</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Exercise is not just about physical health; it’s also vital for
                mental well-being. Running and cycling release endorphins, the
                body’s natural mood lifters. Regular participation in our
                challenges can help reduce stress, anxiety, and depression. The
                sense of achievement and the mental clarity that comes from
                completing a challenge can significantly enhance your overall
                mental health.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Community and Support</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                One of the unique aspects of our challenges is the sense of
                community they create. You’re not alone on your fitness journey.
                By joining our challenges, you become part of a global community
                of like-minded individuals. This support network provides
                encouragement, motivation, and accountability. Sharing your
                progress, celebrating milestones, and learning from others’
                experiences makes the journey more enjoyable and fulfilling.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">
                Flexibility and Accessibility
              </div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Our challenges are designed to be flexible and accessible to
                everyone, regardless of fitness level or location. You can
                participate from anywhere in the world, at any time that suits
                you. Whether you prefer running in the morning, cycling in the
                evening, or fitting in exercise during lunch breaks, our
                challenges accommodate your schedule. This flexibility ensures
                that everyone can take part and reap the benefits of a more
                active lifestyle.F
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Exciting Rewards</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                o keep you motivated, we offer exciting rewards for completing
                our challenges. From exclusive merchandise like t-shirts and
                medals to digital badges and certificates, these rewards serve
                as tangible reminders of your achievements. They’re a testament
                to your hard work and dedication, and they encourage you to keep
                pushing your limits.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">
                Personalized Tracking and Insights
              </div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Our app provides comprehensive tracking tools that help you
                monitor your progress. You can log your runs and rides, track
                your performance, and see how you’re improving over time. The
                insights gained from this data can help you adjust your
                training, set new goals, and stay motivated. Seeing your
                progress in real-time is incredibly rewarding and reinforces
                your commitment to staying active.
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div className="row text-9BA1A2 px-md-3 text-justify lh-25 mb-2 ">
        <p className="fw-500 font-25 text-black lh-25 mb-2">Join the Movement</p>
        <p className="font-18 fw-400">
          At Move, we believe in the power of movement to transform lives. Our
          running and cycling challenges are more than just fitness goals;
          they’re opportunities to build healthier habits, connect with a
          supportive community, and achieve personal milestones. Whether you’re
          aiming for a 100-day streak, a monthly target, or a year- long
          achievement, our challenges are here to support you every step of the
          way.
        </p>
        <p className="font-18 fw-400">
          Join us at Move Wellness Solutions and start your journey towards a
          healthier, fitter, and happier you. Download our app, sign up for a
          challenge, and let’s move together towards a brighter future.
        </p>
        <p className="font-18 fw-400">Stay active and stay healthy,</p>
        <p className="mb-1 text-black fw-500 font-20">Mehul Adajaniya</p>
        <p className="fw-500 font-20">Founder & CEO, Move Wellness Solutions</p>
      </div>
    </>
  );
};

export default FourthBlog;

import React from "react";
import { useAuth } from "../../core/Auth";
import { Icon } from "@iconify/react";

const SignupStep3: React.FC<{ Data: any }> = ({ Data }) => {
  const { saveAuth, setCurrentUser } = useAuth();
  const handleClick = () => {
    saveAuth(Data);
    setCurrentUser(Data);
  };
  return (
    <div className="row ">
      <div className="d-flex col-12 justify-content-center align-items-center mb-3 ">
      <Icon icon="ph:check-fat" className="display-3" />
      </div>
      <div className="col-12">
        <h3 className="auth-font-main text-center fw-600 font-35">
        Your account has been <br/>created successfully.
        </h3>
      </div>
      <p className="p-5 m-4"/>
      <p className="p-5 m-4"/>
      <div
        className="d-flex flex-column justify-content-center col-12 pt-5"
      >
        <button
          className="btn  mb-3  rounded-pill default-background-color d-flex align-items-center justify-content-center  p-sm-1 p-md-2 p-lg-3 p-xl-3 opacity-60 text-center fw-500 font-family-plus-jakarta-sans  "
          onClick={handleClick}
        >
          Let’s run
        </button>
      </div>
    </div>
  );
};

export default SignupStep3;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const DownloadSection = () => {
  return (
    <>
      <Container>
        <Row>
          <Col className="col-12">
            <div className="d-flex justify-content-center flex-column flex-wrap pt-5">
              <h1 className="fw-500  text-center font-54">
                DOWNLOAD THE MOVE <br />
                AND JOIN NOW !{" "}
              </h1>
              <h4 className="fw-500 light-color text-center">
                Let’s Get Moving !
              </h4>
            </div>
          </Col>
          <Col className="col-12">
            <div className="d-flex flex-row flex-wrap gap-3 justify-content-center align-items-center pt-4 pb-5">
              <Link
                to="https://play.google.com/store/apps?hl=en&gl=US"
                target="_blank"
              >
                <img
                  src="\media\assets\svg\download-section-p.svg"
                  alt="download-section-p"
                  className="img-fluid"
                />
              </Link>
              <Link to="https://www.apple.com/in/app-store/" target="_blank">
                <img
                  src="\media\assets\svg\download-section-a.svg"
                  alt="download-section-a"
                  className="img-fluid"
                />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DownloadSection;

import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../module/auth/core/Auth";
import Swal from "sweetalert2";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { Link, useNavigate } from "react-router-dom";
import WebRazorpayResponseModel from "../../module/auth/core/_models";
import useRazorpay from "react-razorpay";
import toastify from "../../helper/toastify";

const EventDescriptionCard = (props: any) => {
  const { getEventDetailQuery, showOtherDetails, isFree, eventID, isjoined,joinFreeEventQuery,joinPaidEventQuery,razorpayPaymentQuery } = props;
  const { auth } = useAuth();
  const navigate = useNavigate();
  const info_icon = "/media/assets/images/info.png";
 
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  

  const toggleEventRules = () => {
    setShowAll(!showAll);
  };

  const [showAll, setShowAll] = useState(false);

  const rulesToShow = showAll
    ? getEventDetailQuery?.data?.result?.eventRules
    : getEventDetailQuery?.data?.result?.eventRules?.slice(0, 1);

  const truncatedDescription =
    getEventDetailQuery?.data?.result?.description
      .replace(/<br>/g, '\n')
      .slice(0, 400) + "...";

  const onJoinEvent = () => {
    if (!auth) {
      Swal.fire({
        title: "You are not logged in",
        text: "To join this event, Please log in to your account. If you don't have an account, you can Sign up",
        icon: "info",
        iconColor: "rgba(254, 204, 9, 1)",
        confirmButtonColor: "rgba(254, 204, 9, 1)",
        confirmButtonText: "Okay",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "rounded-pill fs-5 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-dark fw-500 mt-3 b-0",
          cancelButton: "rounded-pill fs-5 btn  btn-lg px-5 py-2 border  text-dark fw-500 mt-3 b-0"
        },
        focusConfirm: true
      }).then((result) => {
        if (result.isConfirmed) {

          sessionStorage.setItem('returnUrl', window.location.pathname);
          navigate('/auth');
          return;
        }
      });
    } else {
      // if (eventID > 0) {
      //   if (isFree) {
      //     joinFreeEventQuery.mutate({ id: eventID })
      //   }
      //   else {
      //     joinPaidEventQuery.mutate({ id: eventID })
      //   }
      // }
    }
  };
  

  return (
    <>
      <Col xl={6} lg={6} md={12} className="col-12 tab-view-height mt-md-0 mt-tab-3">
        <div className={`card border-0 rounded-4 ${auth ? "mt-2" : ""}`}>
          <div className="text-start mb-3 ps-2 mt-1">
            {getEventDetailQuery?.data?.result?.description && (
              <span className="font-20 fw-500  text-color-black">
                About this event
              </span>
            )}
            {getEventDetailQuery.isLoading ? (
              <ul>
                {Array(4)
                  .fill(null)
                  .map((item, index) => (
                    <li key={index}>
                      <Skeleton height={30} />
                    </li>
                  ))}
              </ul>
            ) : (
              <>
                <ul className="text-justify text-5F6368 fw-400 mt-2">
                  {showFullDescription
                    ? getEventDetailQuery?.data?.result?.description
                      .split("\n")
                      .map((line: any, index: any) => (
                        line !== '' && <li key={index}>{line}</li>
                      ))
                    : truncatedDescription
                      .split("\n")
                      .map((line: any, index: any) => (
                        line !== '' && <li key={index} className="font-16">{line}</li>
                      ))}
                  {getEventDetailQuery?.data?.result?.description
                    .replace(
                      /<br>/g,
                      "\n"
                    )
                    ?.length > 400 && (
                      <span
                        className="fw-400  rounded-pill font-010101 border-1 rounded-pill border-2 text-decoration-underline cursor-pointer font-16"
                        onClick={toggleDescription}
                      >
                        {!showFullDescription ? "Read More" : "Read Less"}
                      </span>
                    )}
                </ul>

                {/* <div
                  className="text-justify text-5F6368 plus-jakarta-sans-font"
                  dangerouslySetInnerHTML={{
                    __html: showFullDescription
                      ? getEventDetailQuery?.data?.result?.description.replace(
                          /\n\n/g,
                          "<br><br>"
                        )
                      : getEventDetailQuery?.data?.result?.description
                          .replace(/\n\n/g, "<br><br>")
                          .slice(0, 600),
                  }}
                /> */}
              </>
            )}
          </div>
          <div className="text-start ps-2">
            {getEventDetailQuery?.data?.result?.eventRules && (
              <span className="font-20 fw-500  text-color-black">
                Event Rules
              </span>
            )}

            {getEventDetailQuery.isLoading &&
              Array(5)
                .fill(null)
                .map((item, index) => (
                  <li key={index}>
                    <Skeleton height={30} />
                  </li>
                ))}
            <ul className="text-justify text-5F6368 text-justify fw-400 ">
              {rulesToShow?.map((rule: any) => (
                <li key={rule.id} className="font-16">{rule.rule}</li>
              ))}
              {getEventDetailQuery?.data?.result?.eventRules.length > 1 && (
                <span
                  className="fw-400 rounded-pill font-010101 border-1 rounded-pill  border-2 text-decoration-underline cursor-pointer font-16"
                  onClick={toggleEventRules}
                >
                  {showAll ? "Read Less" : "Read More"}
                </span>
              )}
            </ul>
          </div>
          <div className="text-start mt-2 ps-2">
            <span className="mb-2 font-20 fw-500 text-color-black">
              Rewards
            </span>
            <div className="fw-400 font-16 text-5F6368">
              You will get an Exclusive e-certificate from Move.
            </div>
            <ul className="custom-list mt-1">
              {auth && (
                <li className="fs-6">
                  <div className="fw-400 text-5F6368 font-14">
                    <>
                      <img src={info_icon} alt="infoIcon" className="bullet-icon" />
                      <span>The name on the certificate will be: {`${auth?.data?.firstName} ${auth?.data?.surname}`}</span>
                    </>
                  </div>
                </li>
              )}
              {getEventDetailQuery?.data?.result?.rewards && (
                <li className="fs-6">
                  <div className="fw-400 text-5F6368 font-14">
                    <img src={info_icon} alt="infoIcon" className="bullet-icon" />
                    {getEventDetailQuery?.data?.result?.rewards}
                  </div>
                </li>
              )}
            </ul>


          </div>
          {
            getEventDetailQuery?.data?.result?.isJoined == true && (
              <div className="mt-2">
                <div>
                  <span className="ps-2 font-20 fw-500 text-color-black">
                    To begin your activity, Please download the app.
                  </span>
                </div>
                <div>
                  <div className="d-flex flex-row flex-wrap gap-3  py-2 ps-2">
                    <Link
                      to="https://play.google.com/store/apps?hl=en&gl=US"
                      target="_blank"
                    >
                      <img
                        src="\media\assets\svg\download-section-p.svg"
                        alt="download-section-p"
                        className="img-fluid"
                        width={120}
                      />
                    </Link>
                    <Link to="https://www.apple.com/in/app-store/" target="_blank">
                      <img
                        src="\media\assets\svg\download-section-a.svg"
                        alt="download-section-a"
                        className="img-fluid"
                        width={120}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {isjoined == true && (
          <>
            <div>
              <Button
                type="submit"
                className="rounded-pill font-20 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-black fw-500 mt-3"
                onClick={onJoinEvent}
              >
                Join now
              </Button>
            </div>
          </>
        )}
      </Col>

    </>
  );
};

export default EventDescriptionCard;

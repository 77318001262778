import React from "react";

const SpinnerLoader = (props: any) => {
  const { loading } = props;
  React.useEffect(() => {
    loading && document.body.classList.add("page-loading");
  }, [loading]);

  return loading ? (
    <div className="page-loader flex-column">
      <span
        className="spinner-border spinner-border-sm text-light fs-6"
        role="status"
      ></span>
    </div>
  ) : null;
};

export default SpinnerLoader;

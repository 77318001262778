import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import { Link } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
  return (
    <main>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="p-lg-5 pb-lg-1 p-0">
                <PageNavigation currentPage={"Privacy Policy"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="px-md-5 pb-md-1 p-0">
            <div className="row">
              <div className="col-md-12">
                <p className="border-E6E6E6 line-height-25-2  fw-400 m-0 font-22">
                  Last Updated : 16/08/2024
                </p>
              </div>
              <div className="col-md-12">
                <p className="font-22 border-E6E6E6 pb-4 pt-2 line-height-25-2 font-000000 fw-400 text-justify">
                  We prioritize the privacy of participants. Prior to delving into specifics, we recommend reviewing our Privacy Label for an overview of our privacy practices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="px-md-5 pb-md-1 p-0">
            <div className="row">
              <div className="col-12">
                <table>
                  <thead>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="fw-600 font-24 d-flex align-items-start">Collection and Sale of Data
                      </td>
                      <td className="fw-400 font-20">
                        <ul>
                          <li>Do we sell your personal information? No.</li>
                          <li>Do we share or sell aggregate information? Yes.</li>
                          <li>Do we share your data with third-party API partners? No.</li>
                          <li>Do we use sensitive categories of data, such as health information? No.</li>
                          <li>Do we delete your data upon account deletion request? Yes.</li>
                          <li>Do we retain your data as long as necessary unless deletion is requested? Yes.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-600 font-24 d-flex align-items-start">Tracking
                      </td>
                      <td className="fw-400 font-20">
                        <ul>
                          <li>Do we use cookies? Yes.</li>
                          <li>Do we track your browsing activities on other sites? No.</li>
                          <li>Do we listen to you using the participant’s device microphone? No.</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-600 font-24 d-flex align-items-start">Communication
                      </td>
                      <td className="fw-400 font-20">
                        <ul>
                          <li>Do we provide advance notice of significant changes to our Privacy Policy? Yes.</li>
                          <li>Do we send you marketing communications? Yes.</li>
                          <li>Do we send push notifications with opt-out options? Yes.</li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12">
                <span className="fw-400 font-20 text-justify">
                  Unless otherwise stated, this Privacy Policy does not apply to third-party products, services, or practices of companies not owned or controlled by MOVE WELLNESS SOLUTIONS / MOVE and its promoters.
                </span>
                <hr />
              </div>
              <div className="col-12">
                <span className="fw-600 font-26">Information Collection by MOVE WELLNESS SOLUTIONS / MOVE</span>
                <p className="fw-400 font-20 text-justify">
                  MOVE WELLNESS SOLUTIONS / MOVE collects information about participants, including data that directly or indirectly identifies participants when they engage in events or challenges hosted by MOVE WELLNESS SOLUTIONS / MOVE. Information is collected through various means such as tracking, completing, or uploading activities. Additionally, MOVE WELLNESS SOLUTIONS / MOVE collects data on how participants use its platform. The following outlines the categories under which information is collected.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  1.  Account and Profile
                </p>
                <p className="fw-400 font-20 text-justify">
                  We collect basic account and profile information to secure and provide participant access to their MOVE WELLNESS SOLUTIONS / MOVE account:
                </p>
                <p className="fw-400 font-20 line-height-25-2">
                  <ul className="ps-4">
                    <li><span className="font-20 font-000000">Participant name</span></li>
                    <li><span className="font-20 font-000000">Email address</span></li>
                    <li><span className="font-20 font-000000">Username and password</span></li>
                  </ul>
                </p>
                <p className="fw-400 font-20">
                  <span className="fw-600 font-20">Use and Accessibility : </span>This information is kept confidential and is neither shared nor sold.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  2. Additional Profile Information
                </p>
                <p className="fw-400 font-20">
                  Participants may provide additional profile information:
                </p>
                <div className="row">
                  <div className="col-6">
                    <p className="fw-400 font-20 line-height-25-2">
                      <ul className="ps-4">
                        <li><span className="font-20 font-000000">Gender</span></li>
                        <li><span className="font-20 font-000000">Date of birth</span></li>
                        <li><span className="font-20 font-000000">Height</span></li>
                        <li><span className="font-20 font-000000">Weight</span></li>
                        <li><span className="font-20 font-000000">Country</span></li>
                      </ul>
                    </p>
                  </div>
                  <div className="col-6">
                    <p className="fw-400 font-20 line-height-25-2">
                      <ul className="ps-4">
                        <li><span className="font-20 font-000000">State</span></li>
                        <li><span className="font-20 font-000000">City</span></li>
                        <li><span className="font-20 font-000000">Address</span></li>
                        <li><span className="font-20 font-000000">Email Id</span></li>
                        <li><span className="font-20 font-000000">T-shirt Size</span></li>
                      </ul>
                    </p>
                  </div>
                </div>
                <p className="fw-400 font-20">
                  <span className="fw-600 font-20 text-justify">Use and Accessibility : </span>This information is used to categorize participants on leaderboards. Except for date of birth and t-shirt size, profile information is publicly visible on leaderboards. Date of birth is used to calculate age for event categorization. Leaderboards are publicly visible on the MOVE WELLNESS SOLUTIONS / MOVE website.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  3. Activity and Use Information
                </p>
                <p className="fw-400 font-20 text-justify">
                  Activity data includes date, time, geo-location, pace, and perceived exertion. MOVE WELLNESS SOLUTIONS / MOVE collects this data via:
                </p>
                <p className="fw-400 font-20 line-height-25-2">
                  <ul className="ps-4">
                    <li><span className="font-20 font-000000">Manual Feed : </span><span className="fw-400 font-20 ">Participants manually input activity data specific to each challenge.</span></li>
                    <li><span className="font-20 font-000000">Activity Links : </span><span className="fw-400 font-20 ">Public urls from third-party applications used to validate activities.</span></li>
                    <li><span className="font-20 font-000000">Direct API Integration : </span><span className="fw-400 font-20 ">Participants authorize MOVE WELLNESS SOLUTIONS / MOVE to pull data from third-party applications.</span></li>
                  </ul>
                </p>
                <p className="fw-400 font-20 text-justify">
                  <span className="fw-600 font-20">Use and Accessibility: </span>Activity data is used for leaderboard rankings and rewarding participants. Public data includes distance, pace, activity link, city, state, country, and age. Use information is aggregated for analytics and anonymized.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  4. Contact Information
                </p>
                <p className="fw-400 font-20 line-height-25-2 text-justify">
                  MOVE WELLNESS SOLUTIONS / MOVE collects contact information including mailing address, email, and phone number when provided by the participant.
                </p>
                <p className="fw-400 font-20 text-justify">
                  <span className="fw-600 font-20">Use and Accessibility: </span>This information is not shared or sold to third parties. It is used for responding to support requests, dispatching t-shirts, rewards, and achievement medals.
                </p>
              </div>
              {/* <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  5. Embedded Content from Other Websites
                </p>
                <p className="fw-400 font-20 text-justify">
                  Articles on the site may include embedded content (e.g., videos, images, articles).
                </p>
                <p className="fw-400 font-20 text-justify">
                  <span className="fw-600 font-20">Use and Accessibility :</span> These websites may collect data, use cookies, embed third-party tracking, and monitor participant interaction with the content.
                </p>
              </div> */}
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  5. Embedded Content from Other Websites
                </p>
                <p className="fw-400 font-20 line-height-25-2 ">
                  Articles on the site may include embedded content (e.g., videos, images, articles).
                </p>
                <p className="fw-400 font-20 text-justify">
                  <span className="fw-600 font-20">Use and Accessibility :</span> These websites may collect data, use cookies, embed third-party tracking, and monitor participant interaction with the content.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  6. Content You Share
                </p>
                <p className="fw-400 font-20 line-height-25-2 text-justify">
                  We collect information from photos, posts, comments, kudos, ratings, reviews, and other content shared by participants.
                </p>
                <p className="fw-400 font-20">
                  <span className="fw-600 font-20 text-justify">Use and Accessibility :</span> Shared content is publicly available on the MOVE WELLNESS SOLUTIONS / MOVE platform for promotion and participant recognition.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  7. Payment Information
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                Participants can register for events via third party platform or directly through an integrated payment gateway (Razorpay).
                </p>
                <p className="fw-400 font-20">
                  <span className="fw-600 font-20 text-justify">Use and Accessibility :</span> MOVE WELLNESS SOLUTIONS / MOVE does not collect or store any payment-related information.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  8. Technical Information and Log Files
                </p>
                <p className="fw-400 font-20 line-height-25-2  text-justify">
                  We collect technical information from participants’ browsers, computers, or mobile devices, including device and network information, cookies, log files, and analytics.
                </p>
                <p className="fw-400 font-20">
                  <span className="fw-600 font-20 text-justify">Use and Accessibility :</span> This information is used for trend analysis, platform administration, security, and aggregate demographic insights. Technical information and log files are not shared or sold to third parties.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-22 line-height-25-2 font-000000 mb-2">
                  9. Other Information
                </p>
                <p className="fw-400 font-20 line-height-25-2  ">
                  We may collect information from third parties or through participant feedback, surveys, and interactions with other participants.
                </p>
                <p className="fw-600 font-20">
                  How MOVE WELLNESS SOLUTIONS / MOVE Uses Information
                </p>
                <p className="fw-400 font-20 text-justify">
                  Information is used to facilitate participation in challenges and events, analyze and improve the platform, and for marketing and promotional purposes. Aggregate information is anonymized and may be shared publicly or with third parties for research, business, or other purposes.
                </p>
                <p className="fw-600 font-20">
                  Service Providers
                </p>
                <p className="fw-400 font-20 text-justify">
                  We may share participant information with third parties providing services to MOVE WELLNESS SOLUTIONS / MOVE, such as postal services, t-shirt vendors, and logistics partners.
                </p>
                <p className="fw-600 font-20">
                  Legal Requirements
                </p>
                <p className="fw-400 font-20 text-justify">
                  We may disclose participant information to comply with legal obligations or to protect the rights, safety, or property of MOVE WELLNESS SOLUTIONS / MOVE, its participants, or others.
                </p>
                <p className="fw-600 font-20 text-justify">
                  How We Protect Information
                </p>
                <p className="fw-400 font-20">
                  We employ industry-standard measures, including SSL technology and advanced firewall technology, to safeguard participant information.
                </p>
                <p className="fw-600 font-20">
                  Deleting Information and Accounts
                </p>
                <p className="fw-400 font-20">
                  Participants can request account deletion. Data is permanently deleted from our systems within 90 days of the request. Public content may remain visible until search engine caches are refreshed.
                </p>
                <p className="fw-600 font-20">
                  Transfers
                </p>
                <p className="fw-400 font-20">
                  The services are operated from India. By using the services, participants consent to the transfer, processing, and storage of their information in India.
                </p>
                <p className="fw-600 font-20">
                  Retention of Information
                </p>
                <p className="fw-400 font-20">
                  We retain participant information as necessary to provide the services, comply with legal obligations, and for other purposes as permitted by law. De-identified or aggregated data may be retained for insights and service improvements.
                </p>
                <p className="fw-600 font-20">
                  Cookies
                </p>
                <p className="fw-400 font-20">
                  We use cookies to personalize and optimize user experience. Participants can manage cookie preferences through browser settings or our website.
                </p>
                <p className="fw-600 font-20">
                  Web Beacons and Other Technologies
                </p>
                <p className="fw-400 font-20">
                  We use web beacons, e-tags, javascript, and local storage objects for analytics and optimization. Third-party vendors, including Google, use cookies for advertising purposes.
                </p>
                <p className="fw-600 font-20">
                  IP Address and Analytics
                </p>
                <p className="fw-400 font-20">
                  We collect IP addresses and technical information for service improvement and legal compliance. Advanced web analytics provides information such as referral urls and browser details.
                </p>
                <p className="fw-600 font-20">
                  External Links
                </p>
                <p className="fw-400 font-20">
                  We are not responsible for the privacy practices of externally linked websites. Participants should review the privacy policies of such sites before providing personal information.
                </p>
              </div>
              <div className="col-12">
                <p className="fw-600 font-20">
                  By accepting MOVE WELLNESS SOLUTIONS , WWW.IAMONTHEMOVE.COM’s Privacy & Terms, you also adhere to Google’s Privacy & Terms.
                </p>
                <p>
                  <Link
                    to="Https://policies.google.com/technologies/partner-sites"
                    target="_blank"
                  >
                    Https://policies.google.com/technologies/partner-sites
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default PrivacyPolicy;

import React, { useEffect, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { AddContactInquiry } from "../../services/Web.type";
import toastify from "../../helper/toastify";
import Loader from "../../Common/loader/Loader";
import SpinnerLoader from "../../Common/loader/SpinnerLoder";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";

const ContactUs: React.FC = () => {
  const initialValues = {
    name: "",
    email: "",
    description: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Please enter name"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter email address"),
    description: Yup.string().trim().required("Please enter your description"),
  });

  const contactUsQuery = usePostQuery("contactUsQuery", AddContactInquiry, {});

  const onSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    const newRecord: any = {
      name: values.name,
      email: values.email,
      description: values.description,
    };
    await contactUsQuery.mutateAsync(newRecord); // Assuming contactUsQuery.mutateAsync is the correct function
    resetForm();
    setSubmitting(false);
  };

  const ref = useRef<any | null>(null);
  const handleStartHere = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handlekeypresschanges = (event: any) => {
    let keySign = event.key;
    if (keySign === "<" || keySign === ">") event.preventDefault();
  };

  useEffect(() => {
    if (contactUsQuery.isSuccess) {
      toastify("success", "Your inquiry has been sent successfully");
    }
  }, [contactUsQuery.isSuccess]);

  return (
    <>
      <main>
        <Loader loading={contactUsQuery?.isLoading} />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-1 p-0">
                  <PageNavigation currentPage={"Contact Us"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="help_details" className="help-details">
          <Container className="contact-form-margin">
            <div className="px-md-5 pt-md-5 mt-md-5 p-0">
              <Row className="align-items-center justify-content-center justify-content-evenly">
                <Col lg={7}>
                  <div className="mb-3">
                    <img
                      className="img-fluid"
                      src="\media\assets\contact-us\frame.png"
                      alt="frame"
                    />
                  </div>
                </Col>
                <Col lg={5} className="col-12">
                  <div className="px-4">
                    <h2 className="text-justify lh-1 mb-4 fw-600 font-30">
                      We are here to help you
                    </h2>
                    <p className="lead  mb-lg-4 fw-400 font-20 text-3C3C3C lh-25 text-justify">
                      We'd love to hear from you! Whether you have questions about the event, need assistance with registration, or want to learn more about sponsorship opportunities, our team is here to help.
                    </p>
                    <div className="d-grid text-start gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3 fw-400 font-22">
                      <button
                        className="btn default-border-color px-4 py-2 fs-6 rounded-pill"
                        type="submit"
                        ref={ref}
                        onClick={handleStartHere}
                      >
                        Start Here
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section
          id="contact_form"
          className="contact-form bg-default bg-mbs-default"
        >
          <Container className="position-relative setmargin">
            <Row>
              <Col
                md={6}
                lg={6}
                xs={12}
                className="p-4 order-md-1 order-1 colorset detail-bottom"
              >
                <div className="text-start tab-form-w-75 tab-form-view">
                  <span className="fw-500 mb-0 font-40">LET'S CHAT.</span>
                  <p className="tab-form font-20 font-md-20 fw-400 lh-25 text-black text-justify">
                    Our support team is ready to assist you with any questions or concerns you may have. Whether you prefer to give us a call or send an email, we've got you covered.
                  </p>
                  <div className=" d-flex align-items-center">
                    <div className="fw-500 font-30 pe-2">EMAIL </div>
                    <div className="font-20 font-md-20 pb-1 fw-400 text-black">
                      info.iamonthemove@gmail.com
                    </div>
                  </div>
                  <div className="d-flex align-content-center">
                    <span className="fw-500 font-30 pe-2 text-uppercase">Phone no </span>
                    <span className="pt-1 font-20 font-md-20 fw-400">
                      +91 9825640664
                    </span>
                  </div>
                  <div className="d-flex-column">
                    <div className="fw-500 font-30 mb-0 text-uppercase">Address </div>
                    <div className="font-20 font-md-20 fw-400 lh-25 text-black">
                      <p className="p-0 mb-0">
                        Move Wellness Solutions
                      </p>
                      <p className=" p-0 mb-0 text-justify">
                        127 Zenon Building, Opp. Unique Hospitals, Bamroli Road, Surat, 395002.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md={5}
                lg={4}
                xs={12}
                className="form-bg form-position form-max-height form-padding rounded-4 order-md-2 order-1 end-0 py-md-5 px-md-5 shadow contactForm"
                style={{
                  position: "absolute",
                  maxHeight: "464px",
                }}
              >
                <div className="" style={{ maxHeight: "400px" }}>
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                  >
                    {({
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      getFieldProps,
                      handleSubmit,
                    }) => {
                      return (
                        <Form onSubmit={handleSubmit} ref={ref} noValidate>
                          <Form.Group className="mb-3 text-start " controlId="">
                            <Form.Label>
                              <span className="fw-500 font-18 lh-25 text-black">Name : <span style={{ color: "red" }}>*</span></span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              {...getFieldProps("name")}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`rounded-pill form-control  border-1 ${errors.name && touched.name
                                ? "border border-danger mb-0"
                                : ""
                                }`}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 text-start "
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>
                            <span className="fw-500 font-18 lh-25 text-black">Email : <span style={{ color: "red" }}>*</span></span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              {...getFieldProps("email")}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`rounded-pill form-control ${errors.email && touched.email
                                ? "border border-danger mb-0"
                                : ""
                                }`}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 text-start "
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>
                            <span className="fw-500 font-18 lh-25 text-black">Message : </span><span style={{ color: "red" }}>*</span> 
                            </Form.Label>
                            <Form.Control
                              type="text"
                              as="textarea"
                              // placeholder="Enter your description"
                              rows={5}
                              {...getFieldProps("description")}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onKeyPress={handlekeypresschanges}
                              className={`rounded-4 form-control ${errors.description && touched.description
                                ? "border border-danger mb-0"
                                : ""
                                }`}
                              style={{ resize: "none" }}
                            />
                          </Form.Group>
                          <Button
                            type="submit"
                            className="btn-default px-5 w-100 rounded-pill border-0 text-black fw-400 font-20 lh-25"
                          >
                            {contactUsQuery?.isLoading ? (
                              <SpinnerLoader
                                loading={contactUsQuery?.isLoading}
                              />
                            ) : (
                              "Send Message"
                            )}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};

export default ContactUs;

import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "authUser";
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

    try {
      const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
      if (auth) {
        return auth;
      }
    } catch (error) {
      console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    }
};
const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth();
      if (auth && auth?.token) {
        config.headers.Authorization = `Bearer ${auth?.token}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

export function getToken() {
  try {
    let token = localStorage.getItem("authUser");
    if (token) {
      token = JSON.parse(token)?.token;
    }
    return token;
  } catch (e) {
    return null;
  }
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };

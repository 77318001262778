const FifthBlog = () => {
  return (
    <>
      <div className="row pb-md-4">
        <div className="col-12">
          <span className="fw-d-din-700 font-42">
            How to Train for a Virtual Marathon: Tips and Tricks
          </span>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-md-8 col-xl-6">
          <div className="row">
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="fw-500 text-9BA1A2 font-20">
                  <span>Written By</span>
                </div>
                <div>
                  <span className="pe-2">
                  <img src="/media/assets/user/blog-user-image.jpeg" alt="blog-profile" width={30} className="rounded-4" />
                  </span>
                  <span className="fw-600 font-20 text-111827">
                   Mehul Adajaniya
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div>
                  <span className="font-20 text-9BA1A2 fw-500">Publish On</span>
                </div>
                <div>
                  <span className="font-20 text-111827 fw-600">
                  5 Jun 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-4">
        <div className="d-flex justify-content-center">
          <img
            src="/media/assets/blogs/blog_5.png"
            className="img-fluid"
            alt="blogimage-5"
          />
        </div>
      </div>
      <div className="row text-9BA1A2 px-md-3">
        <p className="font-18 fw-400">Hello everyone,</p>
        <p className="font-18 fw-400 text-justify">
          I’m Mehul Adajaniya, founder of Move Wellness Solutions. Training for
          a marathon is a challenging yet incredibly rewarding endeavor. With
          the rise of virtual marathons, you can now experience the thrill and
          achievement of completing a marathon from the comfort of your own
          surroundings. Whether you're a seasoned runner or taking on your first
          marathon, these tips and tricks will help you train effectively for
          your virtual marathon.
        </p>
      </div>
      <div className="row mb-2 px-md-3">
        <ol className="list-group list-group-numbered border-0 fw-500 font-25 ">
          <li className="list-group-item d-flex justify-content-between align-items-start border-0 ">
            <div className="ms-2 me-auto">
              <div className="">Set a Realistic Training Schedule</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                The first step in training for a virtual marathon is to
                establish a realistic training plan. Typically, marathon
                training programs range from 12 to 20 weeks, depending on your
                current fitness level and running experience. Your training
                schedule should include a mix of long runs, shorter runs, rest
                days, and cross-training activities.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className="border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Long Runs:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Dedicate one day a week for your long run. This run
                      gradually increases in distance each week.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Shorter Runs:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Include 2-3 shorter runs throughout the week to build
                      endurance and speed.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Rest Days:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Allow time for recovery with at least one or two rest days
                      per week.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Cross-Training:
                    </span>{" "}
                    <span className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                      Incorporate activities like cycling, swimming, or strength
                      training to improve overall fitness and prevent injury.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Invest in Proper Gear</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Having the right gear can make a significant difference in your
                training experience and performance on race day.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Running Shoes:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Invest in a good pair of running shoes that offer the
                      right support and cushioning for your foot type.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Clothing:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Wear moisture-wicking, breathable clothing to stay
                      comfortable during your runs.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Tech Gear:
                    </span>
                    <span className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                      Use a fitness tracker or running app to monitor your
                      progress, track your distance, and stay motivated.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Fuel Your Body</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Proper nutrition is crucial for marathon training. Eating a
                balanced diet helps fuel your runs and aids in recovery.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Carbohydrates:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Ensure you’re getting enough carbohydrates to provide
                      energy for your long runs.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">Protein:</span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Include protein in your diet to help repair and build
                      muscle.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Hydration:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Stay hydrated by drinking plenty of water before, during,
                      and after your runs.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Gradually Increase Mileage</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Avoid the temptation to ramp up your mileage too quickly.
                Gradually increasing your weekly mileage helps build endurance
                and reduces the risk of injury. Follow the 10% rule: increase
                your total weekly mileage by no more than 10% each week.
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Incorporate Speed Work</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Adding speed work to your training can improve your overall pace
                and endurance. Include interval training, tempo runs, and hill
                workouts to build strength and speed.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Interval Training:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Alternate between high-intensity running and recovery
                      periods.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Tempo Runs:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Maintain a steady, challenging pace for a set distance or
                      time.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Hill Workouts:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Run uphill to build strength and stamina.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Prioritize Recovery</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Recovery is just as important as the training itself. Allow your
                body time to heal and rebuild.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Rest Days:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Take rest days seriously to prevent burnout and injury.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Stretching:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Incorporate stretching and foam rolling into your routine
                      to maintain flexibility and prevent muscle tightness.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">Sleep:</span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Aim for 7-9 hours of sleep per night to support recovery
                      and overall health.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Simulate Race Day Conditions</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                As race day approaches, practice under conditions similar to
                what you’ll experience during the virtual marathon.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Route Planning:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Plan and run on the route you’ll use for your virtual
                      marathon to familiarize yourself with the terrain.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">Timing:</span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Run at the same time of day you plan to start your
                      marathon to adapt to the conditions.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Nutrition and Hydration:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Practice your race day fueling and hydration strategy
                      during long runs to see what works best for you.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Mental Preparation</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Marathon training is as much a mental challenge as it is a
                physical one. Prepare yourself mentally for the race.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Visualization:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Visualize crossing the finish line and achieving your
                      goal.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">Mantras:</span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Use positive affirmations and mantras to stay motivated
                      during tough runs.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black">
                      Mindfulness:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Practice mindfulness and meditation to stay focused and
                      calm.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-start border-0">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Join a Supportive Community</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Training for a marathon can be a solitary endeavor, but it
                doesn’t have to be. Join a virtual running group or community to
                share your journey, gain support, and stay motivated.
              </div>
              <div className="fw-500 font-25">
                <ul className="mt-3 list-group-custom ">
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Online Forums:
                    </span>
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Participate in online forums and social media groups
                      dedicated to marathon training.
                    </span>
                  </li>
                  <li className=" border-0 mb-2">
                    <span className="fw-500 font-20 text-black ">
                      Running Buddies:
                    </span>{" "}
                    <span className="fw-400 font-18  text-justify text-9BA1A2">
                      Find virtual running buddies to train with, even if it’s
                      just checking in with each other’s progress.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div className="row text-9BA1A2 px-md-3 text-justify lh-25 mb-2">
        <p className="fw-500 font-25 text-black lh-25 mb-2">Ready, Set, Go!</p>
        <p className="font-18 fw-400">
          Training for a virtual marathon is a rewarding challenge that requires
          dedication, discipline, and the right preparation. By following these
          tips and tricks, you’ll be well on your way to crossing the virtual
          finish line with confidence and pride. At Move Wellness Solutions,
          we’re here to support you every step of the way with our app’s
          comprehensive tracking and community features.
        </p>
        <p className="font-18 fw-400">
          Lace up your shoes, set your goals, and let’s get moving!
        </p>
        <p className="font-18 fw-400">Stay active and stay healthy,</p>
        <p className="mb-1 text-black fw-500 font-20">Mehul Adajaniya</p>
        <p className="fw-500 font-20">Founder & CEO, Move Wellness Solutions</p>
      </div>
    </>
  );
};

export default FifthBlog;

import { Icon } from "@iconify/react";
import React from "react";
import { Dropdown } from "react-bootstrap";

const CheckboxItem = (props: any) => {
  const { id, isChecked, checkHandler, label, icon } = props;
  return (
    <Dropdown.Item href={`#/action-${id}`}>
      <input
        className="form-check-input"
        type="checkbox"
        value=""
        id={id}
        checked={isChecked}
        onChange={checkHandler}
      />
      <label htmlFor={id}>
        {isChecked ? (
          <span style={{ color: "black" }} className="p-1">
            {" "}
            <Icon
              icon={icon}
              fontSize={24}
              style={{ marginTop: "-3px" }}
              width={20}
              height={20}
            />{" "}
            {label}
          </span>
        ) : (
          <span style={{ color: "#5F6368" }} className="p-1">
            {" "}
            <Icon
              icon={icon}
              fontSize={24}
              style={{ marginTop: "-3px" }}
              width={20}
              height={20}
            />
            {" "}
            {label}
          </span>
        )}
      </label>
    </Dropdown.Item>
  );
};

export default CheckboxItem;

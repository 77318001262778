/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line jsx-a11y/alt-text

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { RWebShare } from "react-web-share";
import LazyImage from "react-lazy-blur-image";
import {
  default_imageCycling,
  default_imageRunning,
  default_imageWalking,
} from "../../shared/constants";
import { useAuth } from "../../module/auth/core/Auth";
import { CalculateAge, CalculateBMR, GetCalories } from "../../services/utility";
import { WEB_ENDPOINT } from "../../shared/apiConfig";

const EventDetailsCard = (props: any) => {
  const { getEventDetailQuery, slug, showOtherDetails, isjoined } = props;
  const cycling = "/media/assets/svg/directions-bike.svg";
  const walking = "/media/assets/svg/directions-walk.svg";
  const running = "/media/assets/svg/directions-run.svg";
  const timer = "/media/assets/svg/timer.svg";
  const calender = "/media/assets/images/calender.png";
  const av_timer = "/media/assets/images/av_timer.png";
  const brunt_energy = "/media/assets/images/burnt_energy.png";
  const totalStep = "/media/assets/images/steps.png";
  const [targetDistance, SetTargetDistance] = useState<any>(0.00)
  const [completedDistance, SetCompletedDistance] = useState<any>(0.00)
  const [calorieCount, SetCalorieCount] = useState<any>(0.00)
  const closingDate = moment(
    getEventDetailQuery?.data?.result?.registrationClosingDate
  );
  const { auth } = useAuth();
  const [time, setTime] = useState(new Date());
  const duration = moment.duration(closingDate.diff(moment()));
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day
      }/${year}`;
  };

  const months = duration.months();
  const days = duration.days();
  const resultString = [
    months > 0 ? `${months} Month${months > 1 ? "s" : ""}` : null,
    days > 0 ? `${days} Day${days > 1 ? "s" : ""}` : null,
  ]
    .filter(Boolean)
    .join(" ");

  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 60000);
  }, [time]);

  const formattedStartDate = formatDate(
    getEventDetailQuery?.data?.result?.startDate
  );

  const formattedEndDate = formatDate(
    getEventDetailQuery?.data?.result?.endDate
  );

  useEffect(() => {

    if (getEventDetailQuery?.isSuccess) {
      const isMale = getEventDetailQuery?.data?.result?.gender === 'm' ? true : false;

      SetTargetDistance(getEventDetailQuery?.data?.result?.targetDistance);
      SetCompletedDistance(getEventDetailQuery?.data?.result?.totalDistance);

      if (Number(getEventDetailQuery?.data?.result?.speed) != 0)
        SetCalorieCount(GetCalories(Number(getEventDetailQuery?.data?.result?.speed), getEventDetailQuery?.data?.result?.eventTypeID, auth?.data?.weight, CalculateAge(new Date(auth?.data?.dob)), auth?.data?.height, isMale));

      console.log('age', CalculateAge(new Date(auth?.data?.dob)));
    }

  }, [getEventDetailQuery?.isSuccess])

  return (
    <>
      <Col lg={6} className="col-12">
        <div className="card rounded-4 bg-light border-0">
          <div className="position-relative">
            <div className="overlay rounded-top-4"></div>
            <LazyImage
              uri={
                getEventDetailQuery?.data?.result?.webBannerImage
                  ? getEventDetailQuery?.data?.result?.webBannerImage
                  : getEventDetailQuery?.data?.result?.webBannerImage
                    ? getEventDetailQuery?.data?.result?.webBannerImage
                    : getEventDetailQuery?.data?.result?.eventTypeID === 1
                      ? default_imageCycling
                      : getEventDetailQuery?.data?.result?.eventTypeID === 2
                        ? default_imageWalking
                        : getEventDetailQuery?.data?.result?.eventTypeID === 3
                          ? default_imageRunning
                          : ""
              }
              placeholder={
                getEventDetailQuery?.data?.result?.webBannerImage
                  ? getEventDetailQuery?.data?.result?.webBannerImage
                  : getEventDetailQuery?.data?.result?.webBannerImage
                    ? getEventDetailQuery?.data?.result?.webBannerImage
                    : getEventDetailQuery?.data?.result?.eventTypeID === 1
                      ? default_imageCycling
                      : getEventDetailQuery?.data?.result?.eventTypeID === 2
                        ? default_imageWalking
                        : getEventDetailQuery?.data?.result?.eventTypeID === 3
                          ? default_imageRunning
                          : ""
              }
              render={() => (
                <img
                  src={getEventDetailQuery?.data?.result?.webBannerImage}
                  className="card-img-top rounded-top-4 img-fluid"
                  alt="BannerImage"
                  onError={(
                    e: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    e.currentTarget.src =
                      getEventDetailQuery?.data?.result?.eventTypeID === 1
                        ? default_imageCycling
                        : getEventDetailQuery?.data?.result?.eventTypeID === 2
                          ? default_imageWalking
                          : getEventDetailQuery?.data?.result?.eventTypeID === 3
                            ? default_imageRunning
                            : "";
                  }}
                />
              )}
            />
            <div className="d-flex gap-2 position-absolute top-0 end-0 mx-3 my-3 ">
              <div
                className="d-flex tab-view-share-btn transparent-bg align-items-center rounded-pill px-3 py-1 gap-2 mobile-cycle-only font-12"
              >
                <img
                  src={
                    getEventDetailQuery?.data?.result?.eventTypeID === 1
                      ? cycling
                      : getEventDetailQuery?.data?.result?.eventTypeID === 2
                        ? walking
                        : getEventDetailQuery?.data?.result?.eventTypeID === 3
                          ? running
                          : ""
                  }
                  height={15}
                  alt="event-type"
                />
                <p className="text fw-600  text-uppercase text-black my-0 d-md-block d-none">
                  {getEventDetailQuery?.data?.result?.eventTypeName
                    ? getEventDetailQuery?.data?.result?.eventTypeName
                    : "N/A"}
                </p>
              </div>
              <div
                className="rounded-pill transparent-bg  px-2 py-1 cursor-pointer share-button"
              >
                <RWebShare
                  data={{
                    text: getEventDetailQuery?.data?.result?.name,
                    url: `${WEB_ENDPOINT}/eventdetail/${slug}`,
                    title: getEventDetailQuery?.data?.result?.name,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="13"
                    fill="currentColor"
                    className="bi bi-share-fill"
                    viewBox="0 0 16 16"
                    id="shareButton"
                  >
                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                  </svg>
                </RWebShare>
              </div>
            </div>
          </div>

          <div className="card-body bg-F6F6F6 rounded-bottom-4 position-relative">
            {
              getEventDetailQuery?.data?.result?.isStarted && (
                <div className="position-absolute bottom-0 end-0">
                  <Badge bg="warning" className="rounded-top-0  rounded-start-1" text="dark">
                    <span className="font-18 fw-500">
                      ACTIVE
                    </span>
                  </Badge>
                </div>
              )
            }
            <Row className="mb-3">
              <Col lg={12}>
                <p className="bebas-neue-font card-text font-35">
                  {getEventDetailQuery?.data?.result?.name
                    ? getEventDetailQuery?.data?.result?.name
                    : "-"}
                </p>
              </Col>
              {/* {showOtherDetails && ( */}
              <Col lg={12}>
                <div className="d-flex gap-2 align-items-center">
                  <img src={calender} alt="cal" className="img-fluid" />
                  <p className="fw-500 card-text font-16 font-5E5E5E">
                    Started from{" "}
                    {formattedStartDate ? formattedStartDate : "-"} to{" "}
                    {formattedEndDate ? formattedEndDate : "-"}
                  </p>
                </div>
              </Col>
              {/* )} */}
            </Row>
            <Row className="mb-2">
              <Col className="col-6 text-start">
                <div className="d-flex gap-2 fw-500 font-18 lh-card font-5E5E5E">
                  <img src="/media/assets/svg/arrows_outward.svg" className="img-fluid" alt="total-distance" />
                  <div className="card-text">Total Distance</div>
                </div>
                <div className="bebas-neue-font card-text fs-5 text-color-black-70">
                  {getEventDetailQuery?.data?.result?.unit === 'm' ? (targetDistance * 0.001) : (targetDistance)}{" "}
                  KM
                </div>
              </Col>
              <Col className="col-6 text-start">
                <div className="d-flex gap-2 fw-500 font-18 lh-card font-5E5E5E align-items-center">
                  <img src={showOtherDetails ? av_timer : timer} alt="time" className="img-fluid" />
                  <div className="card-text">
                    {showOtherDetails ? "Active time" : "Event Duration"}
                  </div>
                </div>
                <div className="bebas-neue-font card-text fs-5 text-color-black-70">
                  {getEventDetailQuery?.data?.result?.duration
                    ? getEventDetailQuery?.data?.result?.duration
                    : "N/A"}
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              {!showOtherDetails && isjoined == false && (
                <>
                  {
                    (Number(getEventDetailQuery?.data?.result?.eventTypeID) != 1)
                      ? (
                        <>
                          <Col className="col-6 text-start">
                            <div className="d-flex gap-2 fw-500 font-18 lh-card font-5E5E5E align-items-center">
                              <img src={totalStep} className="img-fluid" alt="total-steps" />
                              <div className="card-text">
                                {"Total distance"}
                              </div>
                            </div>
                            <div className="bebas-neue-font card-text fs-5 text-color-black-70">
                              {(getEventDetailQuery?.data?.result?.totalDistance == 0 || getEventDetailQuery?.data?.result?.totalDistance == null) ? ('0') : Math.floor((getEventDetailQuery?.data?.result?.totalDistance * 0.001))} km
                            </div>
                          </Col>
                        </>
                      )
                      :
                      (
                        <>
                          <Col className="col-6 text-start">
                            <div className="d-flex gap-2 fw-500 font-18 lh-card font-5E5E5E align-items-center">
                              <img src={totalStep} className="img-fluid" alt="total-step" />
                              <div className="card-text">
                                {"Completed Distance"}
                              </div>
                            </div>
                            <div className="bebas-neue-font card-text fs-5 text-color-black-70">
                              {Math.floor((completedDistance * 0.001))}{" "}
                              KM
                            </div>
                          </Col>
                        </>
                      )
                  }
                  <Col className="col-6 text-start">
                    <div className="d-flex gap-2 fw-500 font-18 lh-card font-5E5E5E align-items-center">
                      <img src={brunt_energy} alt="burnt-energy" className="img-fluid" />
                      <div className="card-text">
                        {"Burnt energy"}
                      </div>
                    </div>
                    <div className="bebas-neue-font card-text fs-5 text-color-black-70">
                      {
                        `${calorieCount} cal`
                      }
                    </div>
                  </Col>
                </>
              )}
              <Col className="col-6 text-start">
                <>
                  <div className="d-flex gap-2 fw-500 font-18 lh-card font-5E5E5E">
                    <img src="/media/assets/svg/currency-rupee.svg" alt="joning-fees" className="img-fluid" />
                    <div className="card-text">Joining Fees</div>
                  </div>
                  <div className="bebas-neue-font card-text fs-5 text-color-black-70">
                    {getEventDetailQuery?.data?.result?.joiningFees
                      ? getEventDetailQuery?.data?.result?.joiningFees
                      : "Free"}
                  </div>
                </>
              </Col>
            </Row>
            <Col className="col-8 text-start mt-2 mb-1">
              <div className="d-flex gap-1 align-items-center">
                {resultString ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      fill="#dc3545"
                      className="bi bi-exclamation-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                    <p className="card-text text-danger plus-jakarta-sans-font">
                      {days === 1
                        ? `Event end in ${time.toLocaleTimeString()}`
                        : `Ends in ${resultString}`}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </div>
        </div>
      </Col>
    </>
  );
};

export default EventDetailsCard;

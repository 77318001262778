import { useMutation } from "react-query";
import axios from "axios";
import { BASE_ENDPOINT } from "../shared/apiConfig";
import { getToken } from "../module/auth/core/AuthHelper";

const axiosApi = axios.create({
  baseURL: BASE_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosApi.interceptors.request.use((config: any) => {
  const token = getToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";

  return config;
});

//validate response
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ERR_NETWORK" || error.code === "ERR_FAILED") {
      localStorage.clear();
      return (window.location.href = "/auth");
    }

    return Promise.reject(error);
  }
);

const usePostQuery = (mutationKey = "", url: any, config = {}) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (formData: any = {} as any) => {
      return await axiosApi
        .post(url, formData, config)
        .then((response) => response?.data);
    },
    onSuccess: (res) => {
      return res?.data;
    },
    onError: async (error: any) => {
      return await error?.response?.data;
    },
  });
};

const usePutQuery = (mutationKey = "", url: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (formData: any = {}, config: any = {}) => {
      return await axiosApi
        .put(url, formData, config)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
  });
};

const useGetQuery = (mutationKey = "", url: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (value: any) => {
      return await axiosApi
        .post(url + "/" + value)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      return res?.response;
    },
  });
};

const useTokenQuery = (mutationKey = "", url: any) => {
  return useMutation({
    mutationKey: mutationKey,
    mutationFn: async (value: any) => {
      return await axiosApi
        .post(url + "?token=" + value)
        .then((response) => response.data);
    },
    onSuccess: (res: any) => {
      return res?.data;
    },
    onError: (res: any) => {
      // ShowErrorToast(res);
      return res?.response;
    },
  });
};

export { usePostQuery, usePutQuery, useGetQuery, useTokenQuery };

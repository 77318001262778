import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { usePostQuery } from "../../../../hooks/reactQueryHelper";
import { GenerateOtp, ValidateOtp } from "../../../../services/Web.type";

import { useState, useEffect } from "react";
import { Formik } from "formik";
import { GlobalErrors } from "../../core/_models";
import toastify from "../../../../helper/toastify";
import { Spinner } from "react-bootstrap";

interface Props {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  onNextStep: () => void;
  setSignUpMobile: React.Dispatch<React.SetStateAction<string>>;
}

const SignupStep1: React.FC<Props> = ({
  setIsLogin,
  onNextStep,
  setSignUpMobile,
}) => {
  const singupType = "SignUp";
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpOnceSentClick, setOtpOnceSentClick] = useState(false);
  const [globalErrors, setGlobalErrors] = useState<GlobalErrors>({});
  const [resendTimer, setResendTimer] = useState("180");
  const generateOtpQuery = usePostQuery("generateOtpQuery", GenerateOtp, {});
  const validateOtpQuery = usePostQuery("validateOtpQuery", ValidateOtp, {});

  const initialValues = {
    mobile: "",
    otpCode: "",
    otpRequestType: singupType,
    deviceType: "",
  };
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (otpSent && Number(resendTimer) > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer: string) => {
          const newTimer = Number(prevTimer) - 1;
          return newTimer < 10 ? `0${newTimer}` : newTimer.toString();
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, resendTimer]);

  useEffect(() => {
    if (Number(resendTimer) === 0) {
      setOtpSent(false);
    }
  }, [resendTimer]);

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
    .required("Please enter a mobile number!")
    .trim()
    .min(10, 'Mobile Number must be at least 10 characters!'),

    otpCode: Yup.string()
      .required("Please enter OTP!")
      .matches(/^[0-9]+$/, "Please enter a valid OTP!"),
  });

  const handleGenerateOtp = (values: typeof initialValues) => {
    if (!otpSent) {
      const newRecord = {
        mobile: values.mobile,
        otpRequestType: singupType,
        deviceType: "",
      };

      generateOtpQuery.mutate(newRecord, {
        onSuccess: (data) => {
          setSignUpMobile(newRecord.mobile);
          setOtpSent(true);
          setResendTimer("180");
          setGlobalErrors({
            mobile: "",
          });
          toastify("success", "OTP sent successfully!");
          !otpOnceSentClick && setOtpOnceSentClick(true);
        },
        onError: (error) => {
          const errorMessage =
            error.response?.data?.responseException?.exceptionMessage ||
            "An error occurred while generating OTP.";
          console.error("Error generating OTP:", error);
          setGlobalErrors({
            mobile: errorMessage,
          });
        },
      });
    }
  };

  const handlevalidateOtp = async (values: typeof initialValues) => {
    if (otpSent) {
      setLoading(true);
      const otpData = {
        mobile: values.mobile,
        otpCode: values.otpCode,
        otpRequestType: singupType,
        deviceType: "",
      };
      validateOtpQuery.mutate(otpData, {
        onSuccess: (data) => {
          setGlobalErrors({ otpCode: "" });
          toastify("success", "OTP validated successfully!");
          onNextStep();
        },
        onError: (error) => {
          console.error("Error validating OTP:", error);
          setGlobalErrors({ otpCode: "Please enter a valid OTP!" });
        },
        onSettled: () => {
          setLoading(false);
        },
      });
    }
  };

  const formatTimer = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        validateForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 ">
              <h3 className="auth-font-main fw-600 font-35">
              Create a new account
              </h3>
            </div>
            <div className="col-12">
              <p
                className="auth-font-16 fw-500"
                style={{ textAlign: "left", wordWrap: "break-word" }}
              >
                Please enter your mobile number to verify your account. We will
                send you an OTP on this mobile number.
              </p>
            </div>

            <div className="col-12" style={{ position: "relative" }}>
              <input
                type="text"
                className={`form-control auth-input me-3 rounded p-sm-1 p-md-2 p-lg-3 p-xl-3
              ${errors.mobile || globalErrors.mobile ? "border-danger" : ""}`}
                placeholder="Mobile Number"
                style={{ paddingRight: "100px" }}
                value={values.mobile}
                name="mobile"
                id="mobile"
                onChange={(e) => {
                  setGlobalErrors({
                    mobile: "",
                  });
                  const value = e.target.value;
                  if (e.target.value.length < 11) {
                    if (/^\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }
                }}
                onBlur={handleBlur}
                disabled={otpSent}
              />

              <button
                type="button"
                className="btn btn-link input-inner-right-btn me-2"
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={() => {
                  validateForm().then((validationErrors) => {
                    if (
                      !otpSent &&
                      validationErrors &&
                      validationErrors.mobile
                    ) {
                      console.log(
                        "Validation errors for mobile:",
                        validationErrors.mobile
                      );
                    } else {
                      handleGenerateOtp(values);
                    }
                  });
                }}
                disabled={otpSent}
              >
                {otpOnceSentClick ? `Resend OTP` : "Send OTP"}
              </button>
            </div>
            <div className="col-12">
              {errors.mobile || globalErrors.mobile ? (
                <div className="text-danger mb-3">
                  {errors.mobile || globalErrors.mobile}
                </div>
              ) : (
                <div className="mb-3">
                  {otpSent ? `Resend OTP in ${formatTimer(resendTimer)}` : ""}{" "}
                </div>
              )}
            </div>
            <div className="col-12">
              <input
                type="text"
                className={`form-control auth-input me-3 rounded p-sm-1 p-md-2 p-lg-3 p-xl-3  ${
                  (otpSent && touched.otpCode && errors.otpCode) ||
                  globalErrors.otpCode
                    ? "border-danger"
                    : ""
                }`}
                placeholder="OTP"
                name="otpCode"
                id="otpCode"
                value={values.otpCode}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    if (value.length <= 4) {
                      handleChange(e);
                    }
                  }
                }}
                onBlur={handleBlur}
                disabled={!otpSent}
              />
              {(otpSent && touched.otpCode && errors.otpCode) ||
              globalErrors.otpCode ? (
                <div className="text-danger mb-3">
                  {errors.otpCode || globalErrors.otpCode}
                </div>
              ) : (
                <div className="mb-3"></div>
              )}
            </div>
            <div className="d-flex flex-column col-12  justify-content-center">
              <button
                type="submit"
                className="btn btn-link mb-3 text-decoration-none default-background-color rounded-pill d-flex align-items-center justify-content-center  p-sm-1 p-md-2 p-lg-3 p-xl-3 opacity-60 text-center fw-500 font-family-plus-jakarta-sans  "
                style={{ color: "#010101" }}
                onClick={() => {
                  validateForm().then((validationErrors) => {
                    if (
                      otpSent &&
                      validationErrors &&
                      validationErrors.otpCode
                    ) {
                      console.log(
                        "Validation errors for otpCode:",
                        validationErrors.otpCode
                      );
                    } else {
                      handlevalidateOtp(values);
                    }
                  });
                }}
                disabled={!otpSent}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span className="indicator-label">Continue</span>
                )}
              </button>
            </div>
            <p className="auth-font-16 fw-500 mb-5 ">
              Already have an account?{" "}
              <Link
                to={""}
                onClick={() => {
                  setIsLogin(true);
                }}
                className="auth-font-16 fw-bold"
                style={{ color: "#010101" }}
              >
                Login
              </Link>
            </p>
            <span className="m-4 m-md-5 m-lg-5 " />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default SignupStep1;

import React, { useEffect } from 'react';

const MapComponent: React.FC<{ userActivitySession: any }> = (props) => {
  const { userActivitySession } = props;
  useEffect(() => {
    const initMap = (): void => {
      if (!userActivitySession) return;

      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          zoom: 20,
          center: { lat: userActivitySession.latLongWiseDistance[0].latitude, lng: userActivitySession.latLongWiseDistance[0].longitude },
          mapTypeId: "terrain",
        }
      );

      const flightPlanCoordinates = userActivitySession.latLongWiseDistance.map((x: any) => { return { lat: x.latitude, lng: x.longitude } });

      // Add starting point marker
      const startMarker = new google.maps.Marker({
        position: flightPlanCoordinates[0],
        map: map,
        title: 'Start',
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
        }
      });

      // Add ending point marker
      const endMarker = new google.maps.Marker({
        position: flightPlanCoordinates[flightPlanCoordinates.length - 1],
        map: map,
        title: 'End',
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
        }
      });

      for (let index = 0; index < flightPlanCoordinates.length; index++) {
        const path: any[] = [flightPlanCoordinates[index]]
        if (index + 1 !== flightPlanCoordinates.length)
          path.push(flightPlanCoordinates[index + 1])
        const strokeColor = userActivitySession.latLongWiseDistance[index].isPaused ? '#ff0000' : "#B3E429"
        const polyline = new google.maps.Polyline({
          path: path,
          geodesic: true,
          strokeColor: strokeColor,
          strokeOpacity: 0.7,
          strokeWeight: 4,
          icons: addArrowsAlongPolyline(path, strokeColor)
        });
        polyline.setMap(map);
      }
    };

    // Function to add arrows along the polyline
    const addArrowsAlongPolyline = (coordinates: google.maps.LatLngLiteral[], strokeColor: string) => {
      const icons: google.maps.IconSequence[] = [];
      const numArrows = coordinates.length - 1;

      for (let i = 0; i < numArrows; i++) {
        icons.push({
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 2,
            strokeColor: strokeColor // Arrow color
          },
          offset: `${100 / numArrows * (i + 1)}%`
        });
      }

      return icons;
    };

    // Initialize the map when the component mounts
    initMap();
  }, []);

  // Return an empty fragment
  return <>
      <div id="map"></div>
  </>;
};

export default MapComponent;

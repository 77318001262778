import { Dropdown } from "react-bootstrap";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { GetActivityjournal, GetUpcomingEvents } from "../../services/Web.type";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { Journal, WebJournalFilter, WebJournalResponseModel, webActivityModel } from "../../module/auth/core/_models";
import moment, { weekdays } from "moment";
import { useAuth } from "../../module/auth/core/Auth";
import { DateRangePicker, DefinedRange, Calendar, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Activity from "../../Common/Card/Activity/Activity";
import { classNames } from "react-easy-crop/helpers";
import { GetDateTimeOffset } from "../../services/utility";
import CardSlide from "../../Common/Card/CardSlide";
import BlankPage from "../../Common/BlankData/BlankPage";

interface ActivityHistoryProps {
    page?: boolean;
}

interface EventType {
    id: number;
    name: string;
}

const activityData: webActivityModel = {
    id: 0,
    userID: null,
    eventTypeID: null,
    startDate: undefined,
    endDate: undefined,
    unit: null,
    distance: null,
    eventTypeName: null,
    duration: null,
    eventID: null,
    startLongitude: 0,
    endLongitude: 0,
    startLatitude: 0,
    endLatitude: 0,
    calories: 0,
    eventName: null,
    eventStartDate: undefined,
    eventEndDate: undefined,
    userName: null,
    timeDuration: null,
    activityID: 0,
    startatitude: 0,
    longitude: 0,
    startTime: undefined,
    endTime: undefined
}

const journalData: Journal = {
    date: undefined,
    steps: null,
    activity: []
}

const webJournalResponseModel: WebJournalResponseModel = {
    todayActivity: null,
    journal: []
}

const ActivityHistory: React.FC<ActivityHistoryProps> = () => {
    const getActivityquery = usePostQuery("GetActivityjournal", GetActivityjournal);
    const getUpcomingEventQuery = usePostQuery(
        "getUpcomingEventQuery",
        GetUpcomingEvents,
        {}
    );
    const [historyList, setHistoryList] = useState<WebJournalResponseModel>(webJournalResponseModel);
    const eventTypes: EventType[] = [
        { id: 3, name: "Running" },
        { id: 1, name: "Cycling" },
        { id: 2, name: "Walking" }
    ];

    const [selectedEventTypes, setSelectedEventTypes] = useState<number>(0);
    const changeBtnStyle = (buttonId: string | null) => {
        setActiveButton(buttonId);
        if (buttonId === "All") {
            setSelectedEventTypes(0);
            tabButtonHandler("Filter", 0);
        } else if (buttonId) {
            const eventType = eventTypes.find((type) => type.name === buttonId);
            if (eventType) {
                setSelectedEventTypes(eventType.id);
                tabButtonHandler("Filter", eventType.id);
            }
        }
    };

    const { auth } = useAuth();
    const [activeButton, setActiveButton] = useState<string | null>("All");
    const [dateRange, setDateRange] = useState<any>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'input',
            color: '#fecc09'
        }
    ]);
    const [journalFilter, setJournalFilter] = useState<WebJournalFilter>(
        {
            endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD")
            , id: 0
            , startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD")
            , key: dateRange[0].key, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            , timezonOffset: GetDateTimeOffset(new Date()), eventTypeID: selectedEventTypes
        });



    useEffect(() => {
        if (auth) {
            const fetchData = async () => {
                try {
                    await getActivityquery.mutate(journalFilter);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
            fetchData();
        }
    }, [auth, journalFilter]);

    useEffect(() => {
        getUpcomingEventQuery.mutate({
            search: "",
            eventTypeID: [],
            isGetAll: true,
        });
    }, []);

    useEffect(() => {
        if (getActivityquery.isSuccess && getActivityquery?.data) {
            setHistoryList(getActivityquery?.data?.result);
        }
    }, [getActivityquery.isSuccess, getActivityquery.isLoading])

    const tabButtonHandler = (name: string, eventtypeID: number) => {

        const getTimezoneOffset = GetDateTimeOffset(new Date())
        if (name === 'Filter') {
            journalFilter.startDate = moment(new Date(dateRange[0].startDate.getFullYear(), dateRange[0].startDate.getMonth(), dateRange[0].startDate.getDate(), dateRange[0].startDate.getHours() + 5, dateRange[0].startDate.getMinutes() + 30)).format("YYYY-MM-DD")
            journalFilter.endDate = moment(new Date(dateRange[0].endDate.getFullYear(), dateRange[0].endDate.getMonth(), dateRange[0].endDate.getDate(), dateRange[0].startDate.getHours() + 5, dateRange[0].startDate.getMinutes() + 30)).format("YYYY-MM-DD")
            journalFilter.eventTypeID = eventtypeID;
        }
        setJournalFilter({ ...journalFilter });
    }


    return (<>
        <main>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="p-lg-5 pb-lg-3 p-0">
                                <PageNavigation currentPage={"History"} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="px-lg-5 pb-lg-1 p-0">
                                <div className="row mb-3">
                                    <div className="col-md-10">
                                        <button
                                            type="button"
                                            className={`border-0 px-3 py-2 rounded-pill me-2  fw-600 font-16 text-uppercase ${activeButton === "All" ? "bg-default text-black" : "text-5F6368 bg-F3F3F3"}`}
                                            onClick={() => changeBtnStyle("All")}
                                        >
                                            All
                                        </button>
                                        {eventTypes.map((eventType) => (
                                            <button
                                                key={eventType.id}
                                                type="button"
                                                className={`border-0 fw-600 font-16 px-3 py-2 rounded-pill me-2 my-1 text-uppercase ${activeButton === eventType.name ? ("bg-default text-black") : ("bg-F3F3F3 text-5F6368")}`}
                                                onClick={() => changeBtnStyle(eventType.name)}
                                            >
                                                {eventType?.name === "Running" ?
                                                    (<Icon icon="ic:baseline-directions-run" className={`${activeButton === eventType.name ? "text-black" : "text-5F6368"}`} fontSize={24} />) : (eventType?.name === "Walking" ? (<Icon icon="material-symbols:directions-walk" className={`${activeButton === eventType.name ? "text-black" : "text-5F6368"}`} fontSize={24} />) : (eventType?.name === "Cycling" ? <Icon icon="bx:cycling" className={`${activeButton === eventType.name ? "text-black" : "text-5F6368"}`} fontSize={24} /> : <></>))}
                                                {eventType.name}
                                            </button>
                                        ))}
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-end">
                                        <Dropdown autoClose="outside">
                                            <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="rounded-pill bg-F3F3F3 custom-toggle border-0"
                                            >
                                                <Icon icon="material-symbols:instant-mix" className="text-5F6368" fontSize={24} />
                                                <span className="text-5F6368 ps-2 fw-500 font-16 text-uppercase" data-kt-menu-trigger="click">Filter</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                className="rounded-4 p-2 mt-3"
                                            >
                                                <div style={{ padding: '10px' }} >
                                                    <div className="row">
                                                        <div className="col-md-12 mb-2">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="offset-lg-7 col-lg-5">
                                                                            <span className="font-16 fw-500  text-center">Filter option</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <DateRangePicker
                                                                    onChange={(item: any) => {
                                                                        setDateRange([item.input]);
                                                                    }}
                                                                    moveRangeOnFirstSelection={false}
                                                                    ranges={dateRange}
                                                                    editableDateInputs={true}
                                                                    className="responsive-datepicker fw-400"
                                                                    direction="horizontal"
                                                                    classNames={{ rdrDefinedRangesWrapper: 'd-lg-block d-none', calendarWrapper: 'd-lg-block d-none' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="row px-2">
                                                                <div className="offset-7 col-md-5">
                                                                    <div className="d-flex justify-content-end">
                                                                        <button type="button" className="btn btn-sm rounded-4 border rounded-pill px-3 py-1 btnNavLink  btn-getapp text-color-black fw-500" data-kt-menu-dismiss="true" onClick={() => tabButtonHandler('Filter', selectedEventTypes)}>Apply</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="row mb-md-5">
                                    {
                                        <>
                                            <Activity historyList={historyList} isGetAll={true}  isLoading={getActivityquery?.isLoading}/>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-footer-white mt-5 mb-1">
                <div className="container">
                    <div className="px-md-5 pb-md-2 p-0">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="fw-600  py-5 text-center mb-0 text-uppercase">
                                    upcoming events
                                </h1>
                            </div>
                            <div className="col-12">
                                {getUpcomingEventQuery?.data?.result?.length > 0 ? (
                                    <CardSlide getUpcomingEventQuery={getUpcomingEventQuery} pageName={'upcomingEvent'} isdetailpage={true} />
                                ) : (<BlankPage />)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main >

    </>)
};
export default ActivityHistory;
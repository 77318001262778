/* eslint-disable jsx-a11y/alt-text */
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import LazyImage from "react-lazy-blur-image";
import { Link, useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import {
  AppName,
  default_imageCycling,
  default_imageRunning,
  default_imageWalking,
  RazorPayKey,
} from "../../shared/constants";
import { Button } from "react-bootstrap";
import moment from "moment";
import clsx from "clsx";
import { WEB_ENDPOINT } from "../../shared/apiConfig";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import Swal from "sweetalert2";
import { joinFreeEvent, joinPaidEvent, razorpayPayment } from "../../services/Web.type";
import { useAuth } from "../../module/auth/core/Auth";
import WebRazorpayResponseModel from "../../module/auth/core/_models";
import useRazorpay from "react-razorpay";

const Card = (props: any) => {
  const { item, pageName, isdetailpage } = props;

  const cycling = "/media/assets/svg/directions-bike.svg";
  const walking = "/media/assets/svg/directions-walk.svg";
  const running = "/media/assets/svg/directions-run.svg";
  const navigate = useNavigate();
  const { auth } = useAuth();
  const additionalData = {
    page: false
  }
  const handleButtonClick = () => {
    navigate(`/eventdetail/${item.pageURL}`, { state: additionalData?.page });
  };

  const currentDate = moment(new Date());
  const endDate = moment(item?.endDate);

  const eventDuration = moment.duration(endDate.diff(currentDate));


  const days = eventDuration.days();
  const months = eventDuration.months();
  const hours = eventDuration.hours();

  const durationParts = [];
  if (months > 0) durationParts.push(`${months} month${months > 1 ? 's' : ''}`);
  if (days > 0) durationParts.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) durationParts.push(`${hours} hour${hours > 1 ? 's' : ''}`);

  // Construct duration string
  const eventDurationpartString = durationParts.join(' ');
  const eventDurationString = `Ends in ${eventDurationpartString}`;
  const webRazorpayResponseModel: WebRazorpayResponseModel = {
    orderID: null,
    amount: null,
    currency: null,
    logo: null,
    themeColor: null,
    eventID: null,
    description: null
  }
  const [eventID, setEventID] = useState(0);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [orderDetails, setorderDetails] = useState<WebRazorpayResponseModel>(webRazorpayResponseModel);
  const joinFreeEventQuery = usePostQuery("joinFreeEvent", joinFreeEvent);
  const joinPaidEventQuery = usePostQuery("joinPaidEvent", joinPaidEvent);
  const razorpayPaymentQuery = usePostQuery("razorpayPayment", razorpayPayment);
  const [Razorpay] = useRazorpay();
  const successfullyPaymentAlert = () => Swal.fire({
    title: "Successfully join event!",
    text: "",
    icon: "success",
    iconColor: "rgba(254, 204, 9, 1)",
    confirmButtonColor: "rgba(254, 204, 9, 1)",
    confirmButtonText: "Okay",
    showCancelButton: false,
    customClass: {
      confirmButton: "rounded-pill fs-5 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-dark fw-500 mt-3 b-0"
    },
    focusConfirm: true
  });


  const onJoinEvent = (ID: any, joiningFees: any) => {
    setEventID(ID);
    if (!auth) {
      Swal.fire({
        title: "You are not logged in",
        text: "To join this event, Please log in to your account. If you don't have an account, you can Sign up",
        icon: "info",
        iconColor: "rgba(254, 204, 9, 1)",
        confirmButtonColor: "rgba(254, 204, 9, 1)",
        confirmButtonText: "Okay",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "rounded-pill fs-5 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-dark fw-500 mt-3 b-0",
          cancelButton: "rounded-pill fs-5 btn  btn-lg px-5 py-2 border  text-dark fw-500 mt-3 b-0"
        },
        focusConfirm: true
      }).then((result) => {
        if (result.isConfirmed) {

          // if (!auth) {
          sessionStorage.setItem('returnUrl', window.location.pathname);
          navigate('/auth');
          // toastify(
          //   "error",
          //   "login first to join the event"
          // );
          return;
          // }
        }
      });
    } else {
      if (ID > 0) {
        if (joiningFees == 0) {
          joinFreeEventQuery.mutate({ id: ID })
        }
        else {
          joinPaidEventQuery.mutate({ id: ID })
        }
      }
    }
  };
  useEffect(() => {
    if (joinFreeEventQuery?.isSuccess) {
      navigate('/participations');
      successfullyPaymentAlert();
    }
  }, [joinFreeEventQuery?.isSuccess])

  useEffect(() => {
    if (joinPaidEventQuery?.isSuccess) {
      setorderDetails(joinPaidEventQuery?.data?.result);
      const options = {
        'key': RazorPayKey,
        'amount': `${joinPaidEventQuery?.data?.result?.amount}`,
        'currency': `${joinPaidEventQuery?.data?.result?.currency}`,
        'name': `${AppName}`,
        'order_id': `${joinPaidEventQuery?.data?.result?.orderID}`,
        'image': `${joinPaidEventQuery?.data?.result?.logo}`,
        'description': `${joinPaidEventQuery?.data?.result?.description}`,
        'theme': { 'color': `${joinPaidEventQuery?.data?.result?.themeColor}` },
        // 'prefill': {'contact': user.mobile, 'email': user.email},
        handler: function (response: any) {
          razorpayPaymentQuery.mutate({ userID: 0, eventID: eventID, transactionID: response.razorpay_payment_id, paymentStatus: 2, orderID: response.razorpay_order_id });
          successfullyPaymentAlert();
          navigate('/participations');

        },
      };
      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response: any) {

        Swal.fire({
          title: "Payment failed!",
          titleText: `${response.error.description}`,
          text: `Note down your transaction id : ${response.error.metadata.payment_id}`,
          icon: "error",
          confirmButtonColor: "rgba(254, 204, 9, 1)",
          confirmButtonText: "Okay",
          showCancelButton: false,
          customClass: {
            confirmButton: "rounded-pill fs-5 btn btn-default btn-lg px-5 py-2 border btnNavLink  btn-getapp text-dark fw-500 mt-3 b-0"
          },
          focusConfirm: true
        });

        razorpayPaymentQuery.mutate({ userID: 0, eventID: eventID, transactionID: response.error.metadata.payment_id, paymentStatus: 3, orderID: response.error.metadata.order_id, notes: response.error.description });
      });

      rzp1.open();
    }
  }, [joinPaidEventQuery?.isSuccess])

  useEffect(() => {
    if (razorpayPaymentQuery?.isSuccess) {
      navigate('/participations');
    }
  }, [razorpayPaymentQuery?.isSuccess])

  return (
    <>
      <div
        className="card ofh rounded-4  bg-light border-0"
      >
        {!item.joiningFees &&
          <div className="top-cross-ribbon fw-600 font-12">Free Joining</div>}
        <LazyImage
          uri={
            item.webBannerImage
              ? item.webBannerImage
              : item?.eventTypeID === 1
                ? default_imageCycling
                : item?.eventTypeID === 2
                  ? default_imageWalking
                  : item?.eventTypeID === 3
                    ? default_imageRunning
                    : ""
          }
          placeholder={
            item.webBannerImage
              ? item.webBannerImage
              : item?.eventTypeID === 1
                ? default_imageCycling
                : item?.eventTypeID === 2
                  ? default_imageWalking
                  : item?.eventTypeID === 3
                    ? default_imageRunning
                    : ""
          }
          render={(src: any, style: any) => (
            <img
              src={src}
              className="card-img-top rounded-top-4 img-fluid"
              alt="BannerImage"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src =
                  item?.eventTypeID === 1
                    ? default_imageCycling
                    : item?.eventTypeID === 2
                      ? default_imageWalking
                      : item?.eventTypeID === 3
                        ? default_imageRunning
                        : "";
              }}
            />
          )}
        />

        <div className={`${clsx(isdetailpage ? 'card-body bg-F3F3F3 rounded-bottom-4 p-3 position-relative' : 'card-body bg-footer-white rounded-bottom-4 p-3 position-relative')}`}>
          <span className="mb-0 text-black fw-500 d-md-inline-block text-truncate font-18">
            {`${(item?.eventName.charAt(0).toUpperCase() +
              item?.eventName.toLowerCase()?.slice(1))?.substring(0, 30)}${item?.eventName?.length > 30 ? '...' : ''}`}
          </span>
          <p className="description  text-5F6368 fw-400 font-16 lh-20">
            {item.description}
          </p>
          {pageName != "home" && (
            <p>
              {(item?.activeParticipantCount > 0 && eventDuration.isValid()) ? (<>
                <span className="text-black fw-500 font-14">
                  {item?.activeParticipantCount} people
                </span>{" "}
                <span className="text-5F6368 fw-500 font-14">
                  has already started this event
                </span>
              </>) : (
                <>
                  <span className="text-danger fw-400">
                    {`${item?.isCompleted ? ('Ended on ' + moment(item?.startDate)?.format("DD , MMM YYYY")) : eventDurationString}`}
                  </span>
                </>
              )}
            </p>)}
          <div className="d-flex justify-content-between">
            <Button
              className="text-decoration-none border-0 default-background-color rounded-pill text-dark font-16 fw-600 text-center learn-more"
              onClick={handleButtonClick}
            >
              View more
            </Button>
            {pageName == 'Events' && item?.isSlotAvailable && (item?.isCompleted == false) && (<>
              <Button
                className="text-decoration-none border-0 default-background-color rounded-pill text-dark font-16 fw-600 text-center learn-more"
                onClick={() => {
                  debugger
                  onJoinEvent(item.id, item.joiningFees);
                }}
              >
                Join Now
              </Button>
            </>)}
          </div>
          {
            pageName == 'Events'
              && item?.isCompleted
              ? (
                <>
                  <div className="position-absolute bottom-0 end-0">
                    <button type="button" className="px-3 py-2 border-0 bg-success participatedactivebutton fw-600 font-12 text-uppercase text-light">Completed</button>
                  </div>
                </>
              )
              : (
                pageName == 'Events'
                && item?.isRegistrationClosed
                && (
                  <>
                    <div className="position-absolute bottom-0 end-0">
                      <button type="button" className="px-3 py-2 border-0 bg-danger participatedactivebutton fw-600 font-12 text-uppercase text-light">Registration Closed</button>
                    </div>
                  </>
                )
              )
          }
        </div>
        <div className="d-flex gap-2 position-absolute top-0 my-3 mx-3 end-0">
          <div className="d-flex tab-view-share-btn transparent-bg align-items-center rounded-pill px-3 gap-2 mobile-cycle-only font-12">
            <img
              src={
                item?.eventTypeID === 1
                  ? cycling
                  : item?.eventTypeID === 2
                    ? walking
                    : item?.eventTypeID === 3
                      ? running
                      : ""
              }
              height={16}
              alt="event-type"
            />

            <p className="text text-uppercase my-1 fw-600 text-black d-md-block d-none font-12">
              {item.eventTypeName}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center rounded-pill cursor-pointer border-white transparent-bg gap-2 px-2 py-2 share-button text-black">
            <RWebShare
              data={{
                text: `${item?.pageURL}`,
                url: `${WEB_ENDPOINT}/eventdetail/${item.pageURL}`,
                title: `${item?.pageURL}`,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="13"
                fill="currentColor"
                className="bi bi-share-fill"
                viewBox="0 0 16 16"
                id="shareButton"
              >
                <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
              </svg>
            </RWebShare>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;

import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { usePostQuery } from "../../../../hooks/reactQueryHelper";
import {
  GenerateOtp,
  ValidateOtp,
  SocialSignup,
} from "../../../../services/Web.type";

import { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  appleProvider,
  auth,
  googleProvider,
  // facebookProvider,
  // appleProvider,
} from "../../core/firebaseconfig";
import { signInWithPopup } from "firebase/auth";
import { setAuth } from "../../core/AuthHelper";
import { GlobalErrors } from "../../core/_models";
import { useAuth } from "../../core/Auth";
import toastify from "../../../../helper/toastify";
import { Spinner } from "react-bootstrap";
import { Icon } from "@iconify/react";

interface Props {
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

const LoginPage: React.FC<Props> = ({ setIsLogin }) => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, logout } = useAuth();
  const [otpSent, setOtpSent] = useState(false);
  const [otpOnceSentClick, setOtpOnceSentClick] = useState(false);
  const [globalErrors, setGlobalErrors] = useState<GlobalErrors>({});
  const [resendTimer, setResendTimer] = useState("180");
  const generateOtpQuery = usePostQuery("generateOtpQuery", GenerateOtp, {});
  const validateOtpQuery = usePostQuery("validateOtpQuery", ValidateOtp, {});
  const SocialSignupQuery = usePostQuery("SocialSignupQuery", SocialSignup, {});
  const validateRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (validateRef.current && otpSent) {
      validateRef.current.focus();
    }
  }, [otpSent]);
  const initialValues = {
    mobile: "",
    otpCode: "",
    otpRequestType: "LogIn",
    deviceType: "",
  };
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (otpSent && Number(resendTimer) > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer: string) => {
          const newTimer = Number(prevTimer) - 1;
          return newTimer < 10 ? `0${newTimer}` : newTimer.toString();
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, resendTimer]);

  useEffect(() => {
    if (Number(resendTimer) === 0) {
      setOtpSent(false);
    }
  }, [resendTimer]);

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .required("Please enter a mobile number!")
      .trim()
      .min(10, 'Mobile Number must be at least 10 characters!'),

    otpCode: Yup.string()
      .required("Please enter OTP!")
      .matches(/^[0-9]+$/, "Please enter a valid OTP!"),
  });

  const handleGenerateOtp = (values: typeof initialValues) => {
    if (!otpSent) {
      const newRecord = {
        mobile: values.mobile,
        otpRequestType: "LogIn",
        deviceType: "",
      };

      generateOtpQuery.mutate(newRecord, {
        onSuccess: (data) => {
          setOtpSent(true);
          setResendTimer("180");
          setGlobalErrors({
            mobile: "",
          });
          toastify("success", "OTP sent successfully!");
          !otpOnceSentClick && setOtpOnceSentClick(true);
        },
        onError: (error) => {
          console.error("Error generating OTP:", error);
          setGlobalErrors({
            mobile:
              error.response?.data?.responseException?.exceptionMessage ||
              "An error occurred while Genrate OTP.",
          });
        },
      });
    }
  };

  const handlevalidateOtp: any = async (values: typeof initialValues) => {
    if (otpSent) {
      setLoading(true);
      const otpData = {
        mobile: values.mobile,
        otpCode: values.otpCode,
        otpRequestType: "LogIn",
        deviceType: "",
      };
      await validateOtpQuery.mutate(otpData, {
        onSuccess: (data) => {
          setGlobalErrors({ otpCode: "" });
          setAuth(data.result);
          saveAuth(data.result);
          setCurrentUser(data.result);
          const returnUrl = sessionStorage.getItem('returnUrl') || '/';
          navigate(returnUrl);
        },
        onError: (error) => {
          console.error("Error validating OTP:", error);
          setGlobalErrors({
            otpCode:
              error.response?.data?.responseException?.exceptionMessage ||
              "An error occurred while Validate OTO.",
          });
        },
        onSettled: () => {
          setLoading(false);
        },
      });
    }
  };

  const handleSignInWithApple = async () => {
    await signInWithPopup(auth, appleProvider)
      .then((data: any) => {
        console.log(" signing in with Apple:", data);
        const userData = {
          provider: 3,
          uniqueID: data?.user?.uid || 0,
          firstName: data?._tokenResponse.firstName || "",
          lastName: data?._tokenResponse.lastName || "",
          email: data?.user?.email || "",
          phone: data?.user?.providerData[0]?.phoneNumber || "",
          imageURL: data?.user?.photoURL || "",
        };
        SocialSignupQuery.mutate(userData, {
          onSuccess: (data) => {
            console.log("generateOtpQuery success:", data);
            const returnUrl = sessionStorage.getItem('returnUrl') || '/';
            navigate(returnUrl);
          },
          onError: (error) => {
            const errorMessage =
              error.response?.data?.responseException?.exceptionMessage ||
              "An error occurred while Signup.";
            console.error("An error occurred while Signup:", errorMessage);
            toastify("error", errorMessage);
          },
        });
      })
      .catch((error) => {
        console.error("Error signing in with Apple:", error);
        const errorMessage =
          error.response?.data?.responseException?.exceptionMessage ||
          "An error occurred while Signup.";
        console.error("An error occurred while Signup:", errorMessage);
        toastify("error", errorMessage);
      });
  };

  const handleSignInWithGoogle = async () => {
    await signInWithPopup(auth, googleProvider)
      .then((data: any) => {
        const userData = {
          provider: 1,
          uniqueID: data?.user?.uid || 0,
          firstName: data?._tokenResponse.firstName || "",
          lastName: data?._tokenResponse.lastName || "",
          email: data?.user?.email || "",
          phone: data?.user?.providerData[0]?.phoneNumber || "",
          imageURL: data?.user?.photoURL || "",
        };

        SocialSignupQuery.mutate(userData, {
          onSuccess: async (SocialSignupdata) => {
            toastify("success", "Sign-in successful!");
            saveAuth(SocialSignupdata.result);
            setCurrentUser(SocialSignupdata.result);
            const returnUrl = sessionStorage.getItem('returnUrl') || '/';
            navigate(returnUrl);
          },
          onError: (error) => {
            logout();
            console.error("Error generating OTP:", error);
            const errorMessage =
              error.response?.data?.responseException?.exceptionMessage ||
              "An error occurred while Signup.";
            console.error("An error occurred while Signup:", errorMessage);
            toastify("error", errorMessage);
          },
        });
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
        const errorMessage =
              error.response?.data?.responseException?.exceptionMessage ||
              "An error occurred while Signup.";
            console.error("An error occurred while Signup:", errorMessage);
            toastify("error", errorMessage);
      });
  };

  const formatTimer = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleSignInWithFacebook = () => {
    // signInWithPopup(auth, facebookProvider)
    //   .then((data) => {
    //     console.log(" signing in with Facebook:", data);
    //     SocialSignupQuery.mutate(data, {
    //       onSuccess: (data) => {
    //         console.log("generateOtpQuery success:", data);
    //       },
    //       onError: (error) => {
    //         console.error("Error generating OTP:", error);
    //       },
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error signing in with Facebook:", error);
    //   });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => { }}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        validateForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row ">
            <div className="col-auto">
              <div className="auth-font-main fw-600 font-35  text-justify pb-3">
                <div>
                  WELCOME BACK,
                </div>
                <div>
                  LOGIN TO YOUR ACCOUNT
                </div>
              </div>
            </div>
            <div className="d-flex flex-column col-12">
              <button
                type="button"
                className="btn btn-light mb-3 rounded-pill p-1 p-md-2 p-lg-2 p-xl-2"
                style={{ padding: "0.7rem" }}
                onClick={handleSignInWithApple}
              >
                <Icon icon="mdi:apple" width={28} className="float-start" />
                <span className="auth-font-social fw-600 font-18">Sign In with Apple</span>
              </button>

              <button
                type="button"
                className="btn btn-light mb-3 rounded-pill  p-1 p-md-2 p-lg-2 p-xl-2"
                style={{ padding: "0.7rem" }}
                onClick={handleSignInWithGoogle}
              >
                <Icon
                  icon="flat-color-icons:google"
                  width={28}
                  className="float-start"
                />
                <span className="auth-font-social fw-600 font-18">Sign In with Google</span>
              </button>
              {globalErrors.googleauthentication && (
                <div className="text-danger mb-3 text-center">
                  {globalErrors.googleauthentication}
                </div>
              )}
              <button
                type="button"
                className="btn btn-light mb-3 rounded-pill p-1 p-md-1 p-lg-2 p-xl-2"
                style={{ padding: "0.7rem" }}
                onClick={handleSignInWithFacebook}
              >
                <Icon
                  icon="logos:facebook"
                  width={28}
                  className="float-start"
                />
                <span className="auth-font-social fw-600 font-18">Sign In with Facebook</span>
              </button>
            </div>
            <div className="col-12">
              <p
                className="auth-font-12 text-black fw-500 font-18"
              >
                OR 
              </p>
              <p className="auth-font-18 text-black fw-600 font-18 text-center">
              LOGIN WITH MOBILE NUMBER
              </p>
            </div>
            <div className="col-12" style={{ position: "relative" }}>
              <input
                type="text"
                className={`form-control auth-input me-3 rounded p-sm-1 p-md-2 p-lg-3 p-xl-3
              ${errors.mobile || globalErrors.mobile ? "border-danger" : ""}`}
                placeholder="Mobile Number"
                style={{ paddingRight: "100px" }}
                value={values.mobile}
                name="mobile"
                id="mobile"
                onChange={(e) => {
                  setGlobalErrors({
                    mobile: "",
                  });
                  const value = e.target.value;
                  if (e.target.value.length < 11) {
                    if (/^\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }
                }}
                onBlur={handleBlur}
                disabled={otpSent}
              />

              <button
                type="button"
                className="btn btn-link  input-inner-right-btn me-2"
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={() => {
                  validateForm().then((validationErrors) => {
                    if (
                      !otpSent &&
                      validationErrors &&
                      validationErrors.mobile
                    ) {
                      console.log(
                        "Validation errors for mobile:",
                        validationErrors.mobile
                      );
                    } else {
                      handleGenerateOtp(values);
                    }
                  });
                }}
                disabled={otpSent}
              >
                {otpOnceSentClick ? `Resend OTP` : "Send OTP"}
              </button>
            </div>
            <div className="col-12">
              {errors.mobile || globalErrors.mobile ? (
                <div className="text-danger mb-3">
                  {errors.mobile || globalErrors.mobile}
                </div>
              ) : (
                <div className="mb-3">
                  {otpSent ? `Resend OTP in ${formatTimer(resendTimer)}` : ""}{" "}
                </div>
              )}
            </div>
            <div className="col-12">
              <input
                type="text"
                ref={validateRef}
                className={`form-control auth-input me-3 rounded p-sm-1 p-md-2 p-lg-3 p-xl-3 focus ${(otpSent && touched.otpCode && errors.otpCode) ||
                  globalErrors.otpCode
                  ? "border-danger"
                  : ""
                  }`}
                placeholder="Enter OTP"
                name="otpCode"
                id="otpCode"
                value={values.otpCode}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    if (value.length <= 4) {
                      handleChange(e);
                    }
                  }
                }}
                onBlur={handleBlur}
                disabled={!otpSent}
              />
              {(otpSent && touched.otpCode && errors.otpCode) ||
                globalErrors.otpCode ? (
                <div className="text-danger mb-3">
                  {errors.otpCode || globalErrors.otpCode}
                </div>
              ) : (
                <div className="mb-3"></div>
              )}
            </div>
            <div className="d-flex flex-column  justify-content-center col-12">
              <button
                type="button"
                className="btn btn-link mb-3 text-decoration-none default-background-color rounded-pill d-flex align-items-center justify-content-center  p-sm-1 p-md-2 p-lg-3 p-xl-3 opacity-60 text-center fw-500 font-family-plus-jakarta-sans  "
                style={{ color: "#010101" }}
                onClick={() => {
                  validateForm().then((validationErrors) => {
                    if (
                      otpSent &&
                      validationErrors &&
                      validationErrors.otpCode
                    ) {
                      console.log(
                        "Validation errors for otpCode:",
                        validationErrors.otpCode
                      );
                    } else {
                      handlevalidateOtp(values);
                    }
                  });
                }}
                disabled={!otpSent}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span className="indicator-label fw-500">Get Started</span>
                )}
              </button>
              <p className="auth-font-16 fw-400 font-16" style={{ color: "#010101" }}>
                Don’t have an account?{" "}
                <Link
                  to={""}
                  onClick={() => {
                    setIsLogin(false);
                  }}
                  className="text-center  auth-font-16 fw-500 font-18"
                  style={{ color: "#010101" }}
                >
                  Sign Up
                </Link>
              </p>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default LoginPage;

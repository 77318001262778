import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";

import LoginPage from "../Login/LoginPage";
import SignupStep3 from "./SignupStep3";

const SignupLogin = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [Data, setData] = useState("");
  const [IsLogin, setIsLogin] = useState(true);
  const [SignUpMobile, setSignUpMobile] = useState<string>("");
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <SignupStep1
            setIsLogin={setIsLogin}
            setSignUpMobile={setSignUpMobile}
            onNextStep={() => setCurrentStep(2)}
          />
        );
      case 2:
        return (
          <SignupStep2
            setData={setData}
            onNextStep={() => setCurrentStep(3)}
            SignUpMobile={SignUpMobile}
          />
        );
      case 3:
        return <SignupStep3 Data={Data} />;
      default:
        return null;
    }
  };

  return (
    <div className="row justify-content-center p-3 p-md-5 p-lg-5 p-xl-5 p-xxl-5">
      <div className="col-md-12 col-lg-9  col-xl-9 col-xxl-8 col-12 bg-white rounded-4 shadow p-4 p-md-4 p-lg-5 p-xl-5 p-xxl-5 ">
        <div className="justify-content-center ">
          {currentStep !== 3 && !IsLogin && (
            <div className="stepper mb-4">
              <div className="step active">
                <div className="step-icon">1</div>
              </div>
              <div
                className={`line-between  ${currentStep === 2 ? "active" : ""}`}
              ></div>
              <div className={`step  ${currentStep === 2 ? "active" : ""}`}>
                <div className="step-icon">2</div>
              </div>
            </div>
          )}
          {IsLogin ? <LoginPage setIsLogin={setIsLogin} /> : renderStep()}
          <p className="auth-font-14 fw-400 font-16" style={{ color: "#010101" }}>
            By creating an account, I agree to{" "}
            <span className="auth-font-14 fw-500 ">MOVE</span>
            <br />
            <Link
              to="/terms-conditions"
              className="auth-font-14 fw-500"
              style={{ color: "#010101" }}
              target="_blank" rel="noopener noreferrer"
            >
              Terms of Use
            </Link>
            <span className="auth-font-14 fw-500 m-1">&</span>
            <Link
              to="/privacy-policy"
              className="auth-font-14 fw-500"
              style={{ color: "#010101" }}
              target="_blank" rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignupLogin;

// PrivateRoutes.tsx

import { Route, Routes, Navigate } from "react-router-dom";
import EventDetails from "../Components/EventDetails/EventDetails";
import EmailPage from "../Components/emailPage/EmailPage";
import EditProfile from "../Components/editProfile/EditProfile";
import Profile from "../Components/Profile/Profile";
import Event from "../Components/Event/Event";
import React from "react";
import TransactionHistoryLayout from "../Components/TransactionHistory/TransactionHistoryLayout";
import ParticipatedEvent from "../Components/ParticipatedEvent/ParticipatedEvent";
import ActivityHistory from "../Components/ActivityHistory/ActivityHistory";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/auth" element={<Navigate to="/" />} />
      <Route path="/email" element={<EmailPage />} />
      <Route path="/editprofile" element={<EditProfile />} />
      <Route path="/participations" element={<ParticipatedEvent />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/events" element={<Event />} />
      <Route path="/eventdetail/:slug" element={<EventDetails />} />
      <Route
        path="/transaction-history"
        element={<TransactionHistoryLayout />}
      />
      <Route path="/activity-history" element={<ActivityHistory />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export { PrivateRoutes };

import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import { GetAllTransactions } from "../../services/Web.type";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

interface TransactionHistoryProps {
  page?: boolean;
}

interface Transaction {
  id: number;
  eventID: number;
  userID: number;
  orderID: number | null;
  transactionID: string;
  amount: number;
  createdBy: number;
  amountPaid: number;
  amountDue: number;
  currency: string | null;
  receipt: string | null;
  status: string | null;
  attempts: number;
  notes: string | null;
  createdDate: string;
  entity: any;
  offerID: number | null;
  paymentDate: string;
  firstName: string | null;
  surName: string | null;
  joiningFees: number;
  eventTypeID: number | null;
  paymentStatus: number;
  eventName: string;
  modifiedDate: string;
  modifiedBy: number;
}
const TransactionHistory: React.FC<TransactionHistoryProps> = ({ page }) => {
  const [pagenumber, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const profileMutationQuery = usePostQuery(
    "profileMutation",
    GetAllTransactions
  );
  const handleLoadMore = () => {
    const newLimit = limit + 6;
    const pageLimit = pagenumber;
    if (profileMutationQuery?.data?.result?.length > newLimit) {
      setAllDataLoaded(true);
    }
    setPage(pageLimit);
    setLimit(newLimit);
  };
 

  useEffect(() => {
      profileMutationQuery.mutate({
        search: null,
        eventTypeID: [],
        page: pagenumber,
        limit: limit,
        isGetAll: page
      });
  }, [pagenumber, limit]);

  return (
    <>
      <div className={clsx(page ? 'px-md-5 pb-md-1 p-0' : '')} key={profileMutationQuery?.data?.result?.id}>
        <div className="card bg-F6F6F6 border-0 h-100 p-30 rounded-4">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <span className="bebas-neue-font text-uppercase h3">
                  Transaction history
                </span>
                <span className="text-uppercase font-14 text-decoration-none text-secondary cursor-pointer">
                  {page ? 'Amount' : <Link to="/transaction-history" className="text-uppercase fw-400 font-14 text-decoration-none text-5F6368 cursor-pointer">View All</Link>}
                </span>
              </div>
            </div>
            <div className="col-12">
              {profileMutationQuery?.isSuccess ? (
                profileMutationQuery?.data?.result?.length === 0 ? (
                  <div className="empty-state__content">
                    <div className="empty-profile-state__icon">
                      <img
                        src="/media/assets/events/no-found-gray.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="p-4 font-18">
                      No transactions have been found.
                    </div>
                  </div>
                ) : (
                  profileMutationQuery?.data?.result?.map(
                    (transaction: Transaction, index: number) => (
                      <div key={transaction.id}>
                        <div className="row pb-2">
                          <div className="col-md-6 col-8 ">
                            <div className="d-flex flex-column text-black">
                              <span className="font-16 fw-500 text-capitalize pb-2">
                                {transaction?.eventName.charAt(0).toUpperCase() + transaction?.eventName.toLowerCase().slice(1)}
                              </span>
                              <span
                                className="font-14 d-flex align-items-center"
                                style={{ color: "#5F6368" }}
                              >
                                {transaction?.paymentStatus === 1 ? (
                                  <Icon
                                    icon="material-symbols:pending-actions"
                                    style={{ color: "#FECC09" }}
                                    className="pr-2"
                                    height={20}
                                  />
                                ) : transaction?.paymentStatus === 2 ? (
                                  <Icon
                                    icon="material-symbols:verified"
                                    style={{ color: "#B3E429" }}
                                    className="pr-2"
                                    height={20}
                                  />
                                ) : transaction?.paymentStatus === 3 ? (
                                  <Icon
                                    icon="material-symbols:error"
                                    style={{ color: "#BF1A0F" }}
                                    className="pr-2"
                                    height={20}
                                  />
                                ) : null}
                                <span className="ps-1 fw-400 font-14">
                                  {transaction?.paymentStatus === 1 ? "Pending" : transaction?.paymentStatus === 2 ? "Paid" : transaction?.paymentStatus === 3 ? "Failed" : " "}{" "}
                                  •{" "}
                                  {new Date(
                                    transaction?.paymentDate
                                  ).toLocaleDateString("en-IN", {
                                    day: "2-digit",
                                    month: "long",
                                    year: "numeric",
                                  })}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6 col-4 d-flex justify-content-end align-items-center">
                            <span className="bebas-neue-font font-20">
                              <Icon icon="material-symbols:currency-rupee" />
                              <span>{transaction?.joiningFees.toFixed(2)}</span>
                            </span>
                          </div>
                        </div>
                        {index !== profileMutationQuery?.data?.result?.length - 1 && <hr className="mt-1 "/>}
                      </div>
                    )
                  )
                )
              ) : (
                (page ? (
                  (profileMutationQuery?.isLoading) && Array.from({ length: limit }).map((_, index) => (
                    <div className="row mb-2">
                      <div className="col-12">
                        <Skeleton className="rounded-4" height={50} />
                      </div>
                    </div>
                  ))
                ) : (
                  (profileMutationQuery?.isLoading) && Array.from({ length: 3 }).map((_, index) => (
                    <div className="row mb-2">
                      <div className="col-12">
                        <Skeleton className="rounded-4" height={70} />
                      </div>
                    </div>
                  ))
                ))
              )}
            </div>
            <div className="row">
              {!allDataLoaded &&
                profileMutationQuery?.data?.result?.length >= limit && (
                  <Row className="justify-content-center">
                    <div className="col-12">
                      <div className="button-container">
                        <hr
                          className="hr-text"
                          data-content="Load More"
                          onClick={handleLoadMore}
                        />
                      </div>
                    </div>
                  </Row>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;

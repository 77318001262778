import {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";

import * as authHelper from "./AuthHelper";
import { useNavigate } from "react-router-dom";
import { AuthModel, UserModel } from "./_models";
import { useTokenQuery } from "../../../hooks/reactQueryHelper";
import { validateJwtToken } from "../../../services/Web.type";
import { jwtDecode } from "jwt-decode";

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const navigate = useNavigate();
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    // navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = () => {
  const { auth, setCurrentUser, logout } = useAuth();
  const didRequest = useRef(false);
  const navigate = useNavigate();

  const validationContentQuery: any = useTokenQuery(
    "validateJwtToken",
    validateJwtToken
  );

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          validationContentQuery.mutate(apiToken);
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      }

      return () => (didRequest.current = true);
    };

    if (auth?.token) {
      requestUser(auth?.token);
    } else {
      logout();
    }
  }, [auth?.token]);

  useEffect(() => {
    if (validationContentQuery?.isError) {
      logout();
      navigate('/');
    }
  }, [validationContentQuery?.isError])

  useEffect(() => {
    if (validationContentQuery?.isSuccess) {

      const token: string | undefined = auth?.token; // Ensure token is of type string or undefined
      if (token) {
        const decoded: { [key: string]: any } = jwtDecode(token); // Assuming decoded object can have any properties
        if (decoded && (decoded.mobile === null || decoded?.mobile == "")) {
          navigate('/editprofile');
        }
      }

    }
  }, [validationContentQuery?.isSuccess])
  return null;
};
export { AuthProvider, AuthInit, useAuth };

import React from "react";
import { Col } from "react-bootstrap";
import ActivityCard from "./ActivityCard";

const Activity = (props: any) => {
  const { historyList, isGetAll, isLoading } = props;
  return (
    <>
      {
        <Col lg={12} className="col-md-12 mb-3">
          {(!historyList?.todayActivity) && (
            <>
              <ActivityCard history={historyList?.todayActivity} page={true} isLoading={isLoading} />
            </>
          )}
        </Col>
      }
      {isGetAll && (historyList?.journal?.map((history: any, index: number) => {
        return (<>
          {history?.activity?.length > 0 && (
            <Col lg={12} className="col-md-12 mb-3">
              <ActivityCard history={history} page={false} isLoading={isLoading} />
            </Col>
          )}
        </>)
      }))
      }
      {
        isGetAll && historyList?.journal?.length == 0 && (<>
          <div className="empty-state__content p-2">
            <div className="empty-profile-state__icon p-1">
              <img
                src="/media/assets/events/no-found-gray.png"
                alt="notfound"
                className="img-fluid"
              />
            </div>
            <div className="p-4 fs-6 fw-500">
              No record found.
            </div>
          </div>
        </>)
      }
    </>
  );
};

export default Activity;

import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "../../Common/Card/Card";
import { GetUpcomingEvents } from "../../services/Web.type";
import SkeletonCard from "../../Common/loader/Loader";
import BlankPage from "../../Common/BlankData/BlankPage";
import { usePostQuery } from "../../hooks/reactQueryHelper";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import { MobileSlider } from "../../Common/MobileSlider/MobileSlider";
import AboutUsCard from "../../Common/Card/AboutUs/AboutUsCard";

const AboutUs: React.FC = () => {
  const pathname = window.location.pathname;

  const getUpcomingEventQuery = usePostQuery(
    "getUpcomingEventQuery",
    GetUpcomingEvents,
    {}
  );

  useEffect(() => {
    getUpcomingEventQuery.mutate({
      search: "",
      eventTypeID: [],
      isGetAll: false,
    });
  }, []);

  return (
    <main>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="p-lg-5 pb-lg-3 p-0">
                <PageNavigation currentPage={"About Us"} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Container>
          <Row >
            <Col className="col-12">
              <div className="px-lg-5 pb-lg-4 p-0">
                <Row>
                  <Col lg={12} md={12}>
                    <p className="font-18 fw-400 text-justify mb-3 text-justify lh-25 light-color">
                      Welcome to Move, your ultimate destination for virtual running and cycling events! At Move, we believe in the power of constant motion to transform lives. Our mission is to make fitness accessible and enjoyable for everyone by offering a range of virtual events, including marathons, cyclothons, and various challenges centered around running and cycling. Whether you're a seasoned athlete or a beginner looking to get fitter, move provides a platform where you can compete, track your progress, and connect with a global community of fitness enthusiasts.
                    </p>
                    <p className="font-18 fw-400 text-justify lh-25 light-color">
                      Our user-friendly app allows participants to join events and monitor their performance without needing any third-party platforms. Simply download the Move app from Google Play or the App Store, or log on to our website, and you're ready to start your journey towards a healthier lifestyle. Join us and become a part of a movement that aims to get India fitter, one step at a time.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="about-event-details-us" className="about-event-details-us">
        <Container>
          <div className="px-lg-5 py-lg-5 p-0">
            <Row className="pb-2">
              <span className="font-40 fw-normal fw-600 text-uppercase text-center">
                why us ?
              </span>
              <hr className="border-white border-4"/>
            </Row>
            <Row className="d-flex justify-content-center mb-md-4">
              <AboutUsCard
                page={"about-us"}
                headerTitle={`Seamless Experience`}
                content={`Move offers a seamless experience for participants. Our app integrates event participation and data tracking into one platform, eliminating the need for third-party applications.`}
              />
              <AboutUsCard
                page={"about-us"}
                headerTitle={`Global Community`}
                content={`Join a vibrant community of runners and cyclists from around the world. Compete in events, share your achievements, and motivate each other to keep moving.`}
              />
              <AboutUsCard
                page={"about-us"}
                headerTitle={`Economical Rates`}
                content={`We believe fitness should be accessible to all. That's why we offer our events at economical rates, ensuring that everyone has the opportunity to participate and benefit from our platform.`}
              />
            </Row>
            <Row className="d-flex justify-content-center mb-md-4">
              <AboutUsCard
                page={"about-us"}
                headerTitle={`Exciting Rewards`}
                content={`Participants in our events receive exciting merchandise such as t-shirts, medals, and more upon registration. Celebrate your achievements with tangible rewards that remind you of your dedication and hard work.`}
              />
              <AboutUsCard
                page={"about-us"}
                headerTitle={`Easy Participation`}
                content={`Getting started with Move is simple. Download our app from Google Play or the App Store, or visit our website to register for events and start tracking your performance immediately.`}
              />
            </Row>
          </div>
        </Container>
      </section>
      <section>
        <Container className="mt-5">
          <Row className="pb-2">
            <span className="font-45 text-uppercase fw-500 text-center">
              Benefits
            </span>
          </Row>
          <Row className="d-flex justify-content-center mb-md-4">
            <AboutUsCard
              page={"benefit"}
              headerTitle={`Comprehensive Tracking`}
              content={`Monitor your running and cycling data effortlessly with our integrated app.`}
            />
            <AboutUsCard
              page={"benefit"}
              headerTitle={`Health Improvement`}
              content={`Regular participation in our events can lead to significant positive changes in your health and fitness levels.`}
            />
            <AboutUsCard
              page={"benefit"}
              headerTitle={`Motivation and Support`}
              content={`Engage with a supportive community that encourages you to stay active and reach your fitness goals.`}
            />
          </Row>
          <Row className="d-flex justify-content-center mb-md-4">
            <AboutUsCard
              page={"benefit"}
              headerTitle={`Exclusive Merchandise`}
              content={`Earn exclusive Move merchandise when you register for our events, providing you with memorable keepsakes and additional motivation.`}
            />
            <AboutUsCard
              page={"benefit"}
              headerTitle={`Convenient Access`}
              content={`Participate in events from anywhere in the world, at any time that suits your schedule.`}
            />
          </Row>
        </Container>
      </section>
      <section id="about-event-details-us" className="about-event-details-us">
        <Container className="py-4">
          <div className="px-lg-5 pb-lg-1 p-0">
            <Row className="pb-2 pt-2">
              <span className="font-40 fw-600 text-center text-uppercase">
                About the Founder
              </span>
            </Row>
            <Row>
              <span >
                <p className="font-20 fw-400 text-justify font-5E5E5E lh-25">
                  Move was founded by Mehul Adajaniya, a visionary leader with extensive experience in the event industry.
                  As the CEO of Brand Circus Events, a successful event management company, Mehul has mastered the art of organizing large-scale marathons and endurance sports events.
                  His passion for fitness and wellness has led him to create Move, a platform dedicated to making running and cycling events more accessible and enjoyable for everyone. With his deep knowledge and expertise, Mehul is committed to inspiring a fitter, healthier India through the power of movement
                  <p className="font-20 fw-400 text-justify mt-3 font-5E5E5E">
                    Join us at Move and be a part of the revolution to get India moving towards better health and fitness!
                  </p>
                </p>
              </span>
            </Row>
          </div>
        </Container>
      </section>
      <section>
        <Container className="mt-5">
          <Row className="mb-2">
            <Col className="col-12">
              <div className="px-lg-5 pb-lg-1 p-0">
                <Row>
                  <Col md={12}>
                    <div className="text-center text-uppercase mb-3">
                      <span className="text-center fw-500">
                        <h1 className="text-dark text-uppercase font-45">
                          upcoming events
                        </h1>
                      </span>
                    </div>
                    <Row className="justify-content-center">
                      {getUpcomingEventQuery?.data?.result
                        .slice(0, 3)
                        ?.map((item: any) => (
                          <div className="col-md-6 col-lg-4 col-sm-auto my-2">
                            <Card key={item.id} item={item} pageName={"aboutus"} isdetailpage={true} />
                          </div>
                        ))}
                      <SkeletonCard
                        pathname={pathname}
                        getUpcomingEventQuery={getUpcomingEventQuery}
                      />
                      {getUpcomingEventQuery?.data?.result?.length === 0 && (
                        <BlankPage />
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
      <section className="section-background ">
        <DownloadSection />
      </section>
      <section>
        <MobileSlider />
      </section>
    </main>
  );
};
export default AboutUs;

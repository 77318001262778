import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider,OAuthProvider,FacebookAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAKqX1ICdvcylZrfhMnfJFilHw9gydEIEA",
    authDomain: "iamonthemove-f2203.firebaseapp.com",
    projectId: "iamonthemove-f2203",
    storageBucket: "iamonthemove-f2203.appspot.com",
    messagingSenderId: "833688922864",
    appId: "1:833688922864:web:f055cc4a9685412aea3035",
    measurementId: "G-EJPZCGXB8T"
  };


  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');
  // apple.com
  // appleid.apple.com
  export { auth, googleProvider, facebookProvider, appleProvider };
import { jwtDecode } from "jwt-decode";
import { AUTH_LOCAL_STORAGE_KEY } from "../module/auth/core/AuthHelper";

function useJwt() {
  const isTokenValid = () => {
    try {
      const localStorageData: any = localStorage.getItem(
        AUTH_LOCAL_STORAGE_KEY
      );

      const token: string = localStorageData
        ? JSON.parse(localStorageData).token
        : null;
      if (token) {
        const jwtDecodedToken: any = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds

        return jwtDecodedToken.exp > currentTime;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return { isTokenValid };
}

export default useJwt;

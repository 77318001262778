export const BASE_LAYOUT_CONFIG_KEY = "";
export const USER_IMAGE_UPLOAD_PATH = "/upload/user/";
export const USER_DEFAULT_IMAGE_PATH =
  "/media/assets/avatars/blank.png";
export const EVENT_IMAGE_UPLOAD_PATH = "/upload/event/";
export const EVENT_DEFAULT_IMAGE_PATH = "/media/svg/files/blank-image.svg";
export const EVENT_CYCLING_IMAGE_PATH = "/media/events/directions_bike.svg";
export const EVENT_WALKING_IMAGE_PATH = "/media/events/directions_walk.svg";
export const EVENT_RUNNING_IMAGE_PATH = "/media/events/directions_run.svg";
export const cycling: any = "/media/assets/images/cycle.png";
export const walking: any = "/media/assets/images/walk.png";
export const running: any = "/media/assets/images/run.png";
export const steps: any = "/media/assets/svg/steps.svg";
export const three_star: any = "/media/assets/svg/three_star.svg";
export const default_imageCycling = "/media/assets/events/Default-event-image/cycling2.png";
export const default_imageRunning = "/media/assets/events/Default-event-image/running3.png";
export const default_imageWalking = "/media/assets/events/Default-event-image/walking4.png";
export const RazorPayKey = "rzp_test_e4yrxorkJ5H3xZ";
export const AppName = "Move";

export const MODE = { production: "prod", development: "dev", local: "local" };

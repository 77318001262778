import React from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonItem = () => {
  return (
    <div className="col-md-6 col-xl-4 mb-4 text-center">
      <Skeleton className="skeleton-loaders mobile-loader tab-loader rounded-4" />
    </div>
  );
};

const DetailsLoader = () => {
  return (
    <>
      <Col lg={6} className="col-12">
        <div className="image-container">
          <div className="overlay rounded-4"></div>
          <div className="d-flex transparent-bg align-items-center position-absolute top-0 top-right mx-4 my-4 border border-white rounded-pill px-2 py-2 gap-1 mobile-cycle-only">
            <p className="text plus-jakarta-sans-font fs-6 text-uppercase text-dark my-0">
              {/* <Skeleton duration={1} height={30} width={300} /> */}
            </p>
          </div>
          <div className="d-lg-none transparent-bg align-items-center position-absolute top-0 end-0 mx-4 my-4 border border-white rounded-circle px-2 py-1 mobile-share-only">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-share-fill"
              viewBox="0 0 16 16"
            >
              <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
            </svg>
          </div>
          <Row>
            <div className="col-auto position-absolute bottom-0 start-0 ms-2 mb-3">
              <h2 className="text-white text-start bebas-neue-font mb-0">
                <Skeleton duration={1} height={30} width={300} />
              </h2>
              <p className="text-white text-start fs-6 plus-jakarta-sans-font mb-0">
                <Skeleton duration={1} height={30} width={300} />
              </p>
            </div>
            <div className="col-auto position-absolute bottom-0 end-0 me-lg-2 mb-3">
              <Skeleton
                duration={1}
                height={30}
                width={100}
                className="rounded-4"
              />
            </div>
          </Row>
          <div className="event-details-height">
            <Skeleton
              height={424}
              width={636}
              className="mb-ed-loader tab-mini-loader tab-air-loader tab-pro-loader rounded-4"
            />
          </div>
        </div>
      </Col>
      <Col lg={6} className="col-12 mobile-sc-margin">
        <Skeleton
          height={424}
          width={636}
          className="mb-ed-loader tab-mini-loader tab-air-loader tab-pro-loader rounded-4"
        />
      </Col>
    </>
  );
};

const SkeletonCard = (props: any) => {
  const { pathname, getUpcomingEventQuery, getEventDetailQuery } = props;
  return (
    <>
      {pathname === "/about-us" && getUpcomingEventQuery?.isLoading && (
        <>
          {[...Array(3)].map((_, index) => (
            <SkeletonItem key={index} />
          ))}
        </>
      )}
      {pathname === "/events" && getUpcomingEventQuery?.isLoading && (
        <>
          {[...Array(6)].map((_, index) => (
            <SkeletonItem key={index} />
          ))}
        </>
      )}
      {getEventDetailQuery?.isLoading && <DetailsLoader />}
    </>
  );
};

export default SkeletonCard;

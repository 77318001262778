import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "./Card";

const HomePageEventSlider = (props: any) => {
    const { eventList,pageName } = props;
    const [deviceType, setDeviceType] = useState<string>("desktop");
    const [isCentered, setIsCentered] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 464) {
                setDeviceType("mobile");
                setIsCentered(false);
            } else if (window.innerWidth > 464 && window.innerWidth < 769) {
                setDeviceType("tablet");
                setIsCentered(false);
            } else if (window.innerWidth > 768 && window.innerWidth < 1025) {
                setDeviceType("desktop");
                setIsCentered(true);
            } else {
                setDeviceType("largedesktop");
                setIsCentered(true);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const responsive: any = {
        largeDevice: {
            breakpoint: {
                max: 3000,
                min: 1441
            },
            items: 4,
            partialVisibilityGutter: 30
        },
        desktop: {
            breakpoint: {
                max: 1440,
                min: 1025,
            },
            items: 3,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 769
            },
            items: 2,
        },
        smalldevices: {
            breakpoint: {
                max: 768,
                min: 426
            },
            items: 2,
        },
        mobile: {
            breakpoint: {
                max: 425,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 10
        },
    };

    return (
        
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                centerMode={isCentered}
                focusOnSelect={false}
                minimumTouchDrag={80}
                pauseOnHover={true}
                swipeable={true}
                draggable={true}
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                infinite={eventList?.length > 1}
                autoPlay={deviceType !== "mobile" ? true : false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                transitionDuration={700}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px home-slider h-450"
                customTransition="transform 300ms ease-in-out"
                customLeftArrow={null} 
                customRightArrow={null} 
                slidesToSlide={1}
            >
                {eventList?.map((item: any, index: number) => (
                    <div className={`mx-2`} key={item?.id}>
                        <Card key={item?.id} item={item} pageName={pageName} isdetailpage={true} />
                    </div>
                ))}
            </Carousel>
    );
};

export default HomePageEventSlider;

import Swal from 'sweetalert2'
const showConfirmationDialog = (title : any, text : any, confirmButtonText : any) => {
    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText:  confirmButtonText ,
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        return true; // Resolves with `true` if the user confirmed
      } else {
        return false; // Resolves with `false` if the user canceled
      }
    });
  };
export default showConfirmationDialog;

import React, { useEffect, useState } from "react";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
import TransactionHistory from "./TransactionHistory";
import { Col, Container, Row } from "react-bootstrap";
import { GetEventDetail, GetUpcomingEvents } from "../../services/Web.type";
import { usePostQuery } from "../../hooks/reactQueryHelper";

import { useNavigate } from "react-router-dom";
import CardSlide from "../../Common/Card/CardSlide";
import BlankPage from "../../Common/BlankData/BlankPage";

const TransactionHistoryLayout = () => {
  const slug = window.location.pathname;
  const navigate = useNavigate();
  // const getEventDetailQuery = usePostQuery("getEventDetailQuery", fullUrl);
  // useEffect(() => {
  //   getEventDetailQuery.mutate({});
  // }, []);

 
  const getUpcomingEventQuery = usePostQuery(
    "getUpcomingEventQuery",
    GetUpcomingEvents,
    {}
  );

  useEffect(() => {
    getUpcomingEventQuery.mutate({
      search: "",
      eventTypeID: [],
      isGetAll: true,
    });
  }, []);

  // useEffect(() => {
  //   if (
  //     getEventDetailQuery?.error &&
  //     (getEventDetailQuery.error.response?.status === 400 ||
  //       getEventDetailQuery.error.response?.status === 404 ||
  //       getEventDetailQuery.error.response?.status === 500)
  //   ) {
  //     navigate("/transaction-history");
  //   }
  // }, [getEventDetailQuery?.error, navigate]);

  // const [showAll] = useState(false);

  // const rulesToShow = showAll
  //   ? getEventDetailQuery?.data?.result?.eventRules
  //   : getEventDetailQuery?.data?.result?.eventRules?.slice(0, 3);
  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-1 p-0">
                  <PageNavigation currentPage={"Transaction History"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-12 p-3   ">
                <TransactionHistory page={true} />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-middle-light">
          <Container>
            <div className="px-md-5 pb-md-5 p-0">
              <Row className="row">
                <Col className="col-12">
                  <h1 className="fw-600 pt-5 pb-4 text-center mb-0 text-uppercase">
                    upcoming events
                  </h1>
                </Col>
                <Col className="col-12 ">
                  {getUpcomingEventQuery?.data?.result?.length > 0 ? (
                    <CardSlide getUpcomingEventQuery={getUpcomingEventQuery} pageName={'upcomingEvent'} isdetailpage={true}/>
                  ) : (<BlankPage />)}
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </main>
    </>
  );
};

export default TransactionHistoryLayout;

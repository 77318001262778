import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const alphaBatesClass1: string[] = []
const alphaBatesClass2: string[] = []
const alphaBatesClass3: string[] = []
const alphaBatesClass4: string[] = []

alphabet.forEach((element, index) => {
    if (index % 4 === 0) {
        alphaBatesClass1.push(element)
    }
    else if (index % 4 === 1) {
        alphaBatesClass2.push(element)
    }
    else if (index % 4 === 2) {
        alphaBatesClass3.push(element)
    }
    else if (index % 4 === 3) {
        alphaBatesClass4.push(element)
    }
});

export const applyUserSpecificDateFormat = () => {
    return "DD/MM/yyyy";
};

export const applyUserSesstionFormat = () => {
    return "MMM DD YYYY"
}

export const applyUserSpecificTimeFormat = () => {
    return "hh:mm a";
};

export const applyUserSpecificTimeWithoutMeridiemFormat = () => {
    return "kk:mm";
}

export const GetDateTimeOffset = (date: any) => {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: any) {
            return (num < 10 ? '0' : '') + num;
        };

    return dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}
export const getEventsessionTime = (date: any, eventTypeName: any) => {
    date = new Date(date).getHours();
    var session = '';

    if (eventTypeName === 'Walking') {
        eventTypeName = 'walk';
    } else if (eventTypeName === 'Running') {
        eventTypeName = 'run';
    } else if (eventTypeName === 'Cycling') {
        eventTypeName = 'bike ride'
    }

    if (date >= 6 && date < 12) {
        session = `Morning ${eventTypeName}`;
    } else if (date >= 12 && date < 18) {
        session = `Afternoon ${eventTypeName}`;
    } else if (date >= 18 && date < 21) {
        session = `Evening ${eventTypeName}`;
    } else if (date >= 21 && date < 24) {
        session = `Night ${eventTypeName}`;
    }
    return session;
}
export const applyUserSpecificDateTimeFormat = () => {
    return applyUserSpecificDateFormat() + ' ' + applyUserSpecificTimeFormat();
};

export const convertNumbersWithCommas = (
    x: number | string,
    isDecimalConversionNotNeeded?: boolean,
) => {
    let number: number | string = Number(x)
    if (!isDecimalConversionNotNeeded) number = Number(x).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const addcommasRupeesAmount = (amount: any) => {
    const formattedAmount = amount.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR'
    });
    return formattedAmount;
}


export const currencyPrefix = () => {
    return '&#8377';
}

export const GetMonthWithFormart = (month: number, format: string) => {
    var toDay = new Date()
    toDay.setMonth(month)
    return moment(toDay).format(format)
}

export const GetKilometers = (meters: number) => {
    return (meters / 1000).toFixed(2);
};

export const GetPace = (meters: number, timeDuration: number) => {
    const distanceInKm: any = GetKilometers(meters);
    const pace = ((distanceInKm) / (timeDuration));
    return pace;
}

export const GetInitailCharaterClass = (charter: string) => {
    if (alphaBatesClass1.filter(x => { return x === charter }).length > 0) {
        return 'text-success'
    }
    else if (alphaBatesClass2.filter(x => { return x === charter }).length > 0) {
        return 'text-warning'
    }
    else if (alphaBatesClass3.filter(x => { return x === charter }).length > 0) {
        return 'text-danger'
    }
    else if (alphaBatesClass4.filter(x => { return x === charter }).length > 0) {
        return 'text-info'
    }
}

export function checkImageExists(url: any, callback: any) {
    var img = new Image();
    img.src = url
    img.onload = function () {
        callback(true)
    };
    img.onerror = function () {
        callback(false)
    };
}

export function ConvertDistance(value: any, from: string, to: string) {
    if (from === 'm' && to === 'km') {
        return (value / 1000).toFixed(3).replace(/\.?0+$/, '');
    }
}

export const ConvertTime = (value: any) => {
    let hours = Math.floor(value / 3600)
    let minutes = Math.floor((value - hours * 3600) / 60)
    let seconds = value - hours * 3600 - (minutes * 60)
    let result = ""
    if (hours > 0)
        result += hours + "h "
    if (minutes > 0)
        result += minutes + "min "
    if (seconds > 0)
        result += seconds + "sec "
    return result;
}

export const FocusError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating) {
            let keys = Object.keys(errors);
            if (keys.length > 0) {
                const selector = `[name=${keys[0]}]`;
                const errorElement = document.querySelector(selector) as HTMLElement;
                if (errorElement) {
                    errorElement.focus()
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
        }
    }, [errors, isSubmitting, isValidating]);

    return null;
};
export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export const GetCalories = (speed: number, activityType: number, weight: number, age: number, height: number, isMale: boolean): number => {
    // Determine MET value based on activity type

    const met = activityType === 1 ? 9 : activityType === 2 ? 4 : 9; // 0: cycling, 1: walking, else: running

    // Calculate BMR
    const bmr = CalculateBMR(weight, age, height, isMale);

    // Calculate time
    const time = (60 / speed);

    // Calculate and return calories burned
    return met * bmr * time;
};

// Harris-Benedict formula for BMR
// (88.362 && 447.593) This is the base metabolic rate for a hypothetical man who is at a baseline weight, height, and age (i.e., all other factors are zeroed out). It adjusts the starting point of the calculation.
// (13.397 && 9.247) This value is used to proportionally increase the BMR based on an individual's weight. The higher the weight, the more energy the body requires for maintenance.
// (4.799 && 3.098) This value adjusts the BMR based on an individual's height. Taller individuals generally have a larger body surface area and more lean mass, leading to a higher metabolic rate.
// (5.677 && 4.330) This value decreases the BMR as age increases, reflecting the natural decline in metabolism due to aging.
export const CalculateBMR = (weight: number, age: number, heightInCM: number, isMale: boolean): number => {
    if (isMale) {
        return (88.362
            + (13.397 * weight)
            + (4.799 * heightInCM)
            - (5.677 * age)) / 1440;
    } else {
        return (447.593
            + (9.247 * weight)
            + (3.098 * heightInCM)
            - (4.330 * age)) / 1440;
    }
};
export const CalculateAge = (dateOfBirth: Date): number => {
    const today = new Date();
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const monthDifference = today.getMonth() - dateOfBirth.getMonth();
    const dayDifference = today.getDate() - dateOfBirth.getDate();

    // Adjust if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }
    return age;
}

import { useParams } from "react-router-dom";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import PageNavigation from "../../Common/PageNavigation/PageNavigation"
import FirstBlog from "./BlogContent/FirstBlog";
import SecondBlog from "./BlogContent/SecondBlog";
import ThirdBlog from "./BlogContent/ThirdBlog";
import FourthBlog from "./BlogContent/FourthBlog";
import FifthBlog from "./BlogContent/FifthBlog";

const BlogDetails = () => {
    const { slug } = useParams();
    return (
        <>
            <main>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="p-lg-5 pb-lg-3 p-0">
                                    <PageNavigation currentPage={"blog details"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="p-lg-5 pb-lg-3  p-0">
                                   {slug==='1' && <FirstBlog />} 
                                   {slug==='2' && <SecondBlog />} 
                                   {slug==='3' && <ThirdBlog />} 
                                   {slug==='4' && <FourthBlog />} 
                                   {slug==='5' && <FifthBlog />} 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-background ">
                    <DownloadSection />
                </section>
            </main>
        </>
    )
}

export default BlogDetails;
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import Card from "./Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";

const CardSlide = (props: any) => {
  const { getUpcomingEventQuery, isdetailpage, pageName } = props;
  const navigate = useNavigate();
  const [deviceType, setDeviceType] = useState<string>("desktop");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 464) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        infinite={true}
        autoPlay={deviceType !== "mobile" ? true : false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px h-500"
        customLeftArrow={null}
        customRightArrow={null} // Remove right arrow
        customDot={null}
      >
        {getUpcomingEventQuery?.data?.result?.map((item: any) => (
          <div className="mx-2" key={item?.id}>
            <Card key={item.id} item={item} pageName={pageName} isdetailpage={isdetailpage} />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default CardSlide;

import { MODE } from "./constants";

console.log("Mode", MODE);
console.log(
  "process.env.REACT_APP_FITEVENT_MODE",
  process.env.REACT_APP_FITEVENT_MODE
);
export const isDevelopment = () =>
  process.env.REACT_APP_FITEVENT_MODE === MODE?.development;
export const isProduction = () =>
  process.env.REACT_APP_FITEVENT_MODE === MODE?.production;
export const isLocal = () =>
  process.env.REACT_APP_FITEVENT_MODE === MODE?.local;

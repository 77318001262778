import React from "react";
import { Dropdown } from "react-bootstrap";

const RadioGroupInput = (props: any) => {
  const { id, isChecked, checkHandler, label } = props;
  return (
    <Dropdown.Item href={`#/action-${id}`}>
      <input
        className="form-check-input"
        type="radio"
        value=""
        id={id}
        name={"radioGroup"}
        checked={isChecked}
        onChange={checkHandler}
      />
      <label htmlFor={id}>
        {isChecked ? (
          <span className="p-1 text-dark">
            {label}
          </span>
        ) : (
          <span style={{ color: "#5F6368" }} className="p-1">
            {label}
          </span>
        )}
      </label>
    </Dropdown.Item>
  );
};

export default RadioGroupInput;

const ThirdBlog = () => {
  return (
    <>
      <div className="row pb-md-4">
        <div className="col-12">
          <span className="fw-d-din-700 font-42">
            Corporate Wellness Programs: A Win-Win Strategy
          </span>
        </div>
      </div>
      <div className="row pb-4">
        <div className="col-md-8 col-xl-6">
          <div className="row">
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="fw-500 text-9BA1A2 font-20">
                  <span>Written By</span>
                </div>
                <div>
                  <span className="pe-2">
                  <img src="/media/assets/user/blog-user-image.jpeg" alt="blog-profile" width={30} className="rounded-4" />
                  </span>
                  <span className="fw-600 font-20 text-111827">
                  Mehul Adajaniya
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div>
                  <span className="font-20 text-9BA1A2 fw-500">Publish On</span>
                </div>
                <div>
                  <span className="font-20 text-111827 fw-600">
                  3 Jun 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pb-4">
        <div className="d-flex justify-content-center">
          <img
            src="/media/assets/blogs/blog_3.png"
            className="img-fluid"
            alt="blog-3"
          />
        </div>
      </div>
      <div className="row text-9BA1A2 px-md-3">
        <p className="font-18 fw-400 text-justify">
          At Move Wellness Solutions, we understand the transformative power of
          corporate wellness programs. Our tailored solutions are designed to
          meet the unique needs of organizations, offering structured challenges
          and activities that promote physical and mental well-being. Here’s how
          our programs can benefit your company:
        </p>
      </div>
      <div className="row mb-2 px-md-3">
        <ul className="list-group border-0 fw-500 font-25 ">
          <li className="ms-5 border-0 mb-4">
            <div className="ms-2 me-auto">
              <div className="">Customizable Plans:</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                We offer 30-day, 90-day, and yearly plans to suit your
                organization’s specific needs and goals.
              </div>
            </div>
          </li>
          <li className="ms-5 border-0 mb-4">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Engaging Challenges:</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Our programs include exciting fitness challenges that encourage
                participation and foster a sense of achievement.
              </div>
            </div>
          </li>
          <li className="ms-5 border-0 mb-4">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Holistic Approach:</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                We focus on overall well-being, including physical fitness,
                mental health, nutrition, and stress management.
              </div>
            </div>
          </li>
          <li className="ms-5 border-0 mb-4">
            <div className="ms-2 me-auto">
              <div className="fw-500 font-25">Measurable Outcomes:</div>
              <div className="fw-400 font-18 lh-25 text-justify text-9BA1A2">
                Track progress and measure the impact of wellness initiatives on
                employee health and productivity.
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="row text-9BA1A2 px-md-3 text-justify lh-25 mb-2">
        <p className="font-18 fw-400">
          Investing in corporate wellness programs is a strategic decision that
          yields substantial returns. By fostering a healthy, engaged, and
          productive workforce, companies can drive better business outcomes and
          create a positive, thriving workplace culture.
        </p>
        <p className="font-18 fw-400">
          Join us at Move Wellness Solutions in our mission to promote health
          and well-being in the workplace. Together, we can move towards a
          healthier, fitter future.
        </p>
        <p className="font-18 fw-400">Stay active and stay healthy,</p>
        <p className="mb-1 text-black fw-500 font-20">Mehul Adajaniya</p>
        <p className="fw-500 font-20">Founder & CEO, Move Wellness Solutions</p>
      </div>
    </>
  );
};

export default ThirdBlog;

import Card from "../../Common/Card/Blogs/Card";
import DownloadSection from "../../Common/DownloadSection/DownloadSection";
import PageNavigation from "../../Common/PageNavigation/PageNavigation";
const data = [
  {
    id:1,
    name: "Mehul Adajaniya",
    date: "2024-06-01",
    blogImage: "../media/assets/Blogs/blog_1.png",
    title:"How Virtual Running and Cycling Events Keep You Healthy",
    description: "One of the biggest barriers to regular exercise is finding the time and place to do it. Virtual running and cycling events eliminate this obstacle by allowing you to participate from anywhere. Whether you’re at home, in a park, or on a business trip, all you need is your device and a bit of motivation. This flexibility ensures that you can stay consistent with your fitness routine, no matter how busy your schedule gets.",
  },
  {
    id:2,
    name: "Mehul Adajaniya",
    date: "2024-06-02",
    blogImage: "../media/assets/Blogs/blog_2.png",
    title:"Why Big Corporates Are Betting on Corporate Wellness Programs for a Healthy Workforce",
    description: "Corporate wellness programs are no longer just a perk; they’re becoming an integral part of company culture. Businesses worldwide recognize that the health and well- being of their employees directly impact productivity, engagement, and overall success. Here’s why major corporations are betting big on these programs:",
  },
  {
    id:3,
    name: "Mehul Adajaniya",
    date: "2024-06-03",
    blogImage: "../media/assets/Blogs/blog_3.png",
    title:"Corporate Wellness Programs: A Win-Win Strategy",
    description: "At Move Wellness Solutions, we understand the transformative power of corporate wellness programs. Our tailored solutions are designed to meet the unique needs of organizations, offering structured challenges and activities that promote physical and mental well-being. Here’s how our programs can benefit your company:",
  },
  {
    id:4,
    name: "Mehul Adajaniya",
    date: "2024-06-04",
    blogImage: "../media/assets/Blogs/blog_4.png",
    title:"How Our Running and Cycling Challenges Benefit Individual Participants",
    description: "One of the biggest hurdles in maintaining a fitness routine is consistency. Our challenges are designed to help you build and maintain regular exercise habits. Whether it’s a 100-day challenge or a year-long commitment, having a structured goal keeps you motivated and disciplined. The regularity of these challenges ingrains a sense of routine, making physical activity a natural part of your daily life.",
  },
  {
    id:5,
    name: "Mehul Adajaniya",
    date: "2024-06-05",
    blogImage: "../media/assets/Blogs/blog_5.png",
    title:"How to Train for a Virtual Marathon: Tips and Tricks",
    description: "One of the biggest barriers to regular exercise is finding the time and place to do it. Virtual running and cycling events eliminate this obstacle by allowing you to participate from anywhere. Whether you’re at home, in a park, or on a business trip, all you need is your device and a bit of motivation. This flexibility ensures that you can stay consistent with your fitness routine, no matter how busy your schedule gets.",
  },
];

const Blogs = () => {
  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="p-lg-5 pb-lg-1 p-0">
                  <PageNavigation currentPage={"Blogs"} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row mb-2">
              <div className="col-12">
                <div className="px-lg-5 px-lg-4 py-lg-5 py-3  px-4">
                  <div className="row">
                    {data.map((item, index) => (
                      <Card key={index} item={item} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-background">
          <DownloadSection />
        </section>
      </main>
    </>
  );
};

export default Blogs;